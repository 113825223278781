import { FC, ReactNode, useState } from 'react';
import { Box, Button, Text, Card, Image, Avatar, useToast, useDisclosure, Flex } from '@chakra-ui/react';
import { getEllipsisTxt, separator } from 'utils/format';
import { useRouter } from 'next/router';
import { event } from 'nextjs-google-analytics';
import Loading from '@/components/modules/Loading';
import { events } from '@/constants/analytics';
import EmptyState from '@/components/elements/EmptyState';
import MsgPop from '../constomer/MsgPop/MsgPop';
import { ConsumerPointsInfo } from '../../../types';
import { useBluwhale } from '@/context/BluwhaleContext';
import { useFetchOccupiedSpots } from '@/hooks/useFetchOccupiedSpots';
import ConsumerBuySpot from '../constomer/MsgPop/ConsumerBuySpot';
import { sendMsgList } from '../../../pages/api/enterpriseapi';

interface SideInfoHuntingListProps {
  myAddress?: string;
  huntingList: { saved: any[]; created: any[] };
  display?: object;
}

const SideInfoHuntingList: FC<SideInfoHuntingListProps> = (props) => {
  const { myAddress = '', huntingList, display = {} } = props;
  // console.log('🚀 ~ file: SideInfoHuntingList.tsx:14 ~ huntingList:', huntingList);
  const router = useRouter();
  const toast = useToast();
  const { user, axiosInstance } = useBluwhale();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [targetAddress, setTargetAddress] = useState('');
  const [targetImage, setTargetImage] = useState('');
  const [otherUserPoints, setOtherUserPoints] = useState<ConsumerPointsInfo | undefined>(undefined);
  const { data: spots, status, refetch: refetchOccupiedSpots } = useFetchOccupiedSpots();
  const { isOpen: isBuyOpen, onOpen: onBuyOpen, onClose: onBuyClose } = useDisclosure();
  const [targetUser, setTargetUser] = useState(undefined);

  const handleMyGroupClick = () => {
    if (!myAddress) {
      toast({
        description: `Please connect your wallet before proceeding`,
        status: 'error',
        position: 'top',
      });
      return;
    }

    event(events.btn_learn_more);
    event(events.btn_my_group_result);

    router.push(`/group/me`);
  };

  const handleWalletClick = (address: string) => {
    router.push(`/wallet/${address}`);
  };

  const handleGroupClick = (address: string) => {
    router.push(`/group/${address}`);
  };
  const openMessage = async (wallet: any) => {
    event(events.btn_msg_click, {
      target_user: `.${wallet?.address}.`,
    });
    const exist = spots?.find((spt: any) => spt?.address?.toLowerCase() === wallet?.address?.toLowerCase());
    setTargetUser(wallet);
    setTargetAddress(wallet?.address);
    if (exist) {
      setTargetImage('/images/demo-avatar.png');
      onOpen();
    } else {
      onBuyOpen();
    }
  };

  const sendEmptyMessage = async () => {
    if (user?.address) {
      await sendMsgList(user?.address, targetAddress, ' ', '', axiosInstance);
      await refetchOccupiedSpots();
      // setTimeout(() => {
      //   openMessage(targetAddress);
      // }, 1000);
    }
  };

  return (
    <Box display={display}>
      <Text fontWeight="500" fontSize="26px" letterSpacing="-1px" mb={6} ml={1}>
        Following List
      </Text>
      {/* My Group */}
      {huntingList.created.map((item, idx) => (
        <Card key={idx} rounded="23px" py="24px" bg="#262626" mb={3} px="5">
          <Loading loading={huntingList.created.length === 0} minH="400px">
            <Box position="relative" mb={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Text fontSize={'17px'}>{item.name || 'My Group'}</Text>{' '}
              {/* {item.wallets.length > 0 && (
                <Image src="/images/icon/toGroup.png" cursor={'pointer'} onClick={handleMyGroupClick} />
              )} */}
            </Box>
            <Box pt={2} maxH="220px" overflowY={item.wallets.length > 4 ? 'scroll' : 'initial'}>
              {myAddress && item.wallets.length > 0 ? (
                item.wallets.map((wallet: any, index: number) => (
                  <Box
                    key={index}
                    py={1}
                    display={'flex'}
                    alignItems={'center'}
                    rounded="8"
                    // bg={wallet.isNew ? '#6236D0' : 'transparent'}
                    justifyContent={'space-between'} // Adjusted property
                    className={wallet.isNew ? 'fade-in-item' : ''}
                    cursor="pointer"
                    onClick={() => handleWalletClick(wallet.address)}
                  >
                    <Flex alignItems={'center'}>
                      <Image
                        w={'35px'}
                        h={'35px'}
                        mr={6}
                        src={wallet?.photo_url ?? '/images/default_avatar.png'}
                        rounded="lg"
                      />
                      <Text textDecoration="underline" fontSize="17px">
                        {wallet?.display_name || getEllipsisTxt(wallet.address)}
                      </Text>
                    </Flex>

                    <Image
                      src={`/images/icon/mailland.png`}
                      cursor={'pointer'}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openMessage(wallet);
                      }}
                      w={8}
                      h={8}
                      alignSelf={'flex-end'}
                    />
                  </Box>
                ))
              ) : (
                <Box px="4" py="80px">
                  <Text color="#888" display="inline-block" mx="auto" fontSize="sm" textAlign="center">
                    Please click the "Follow" button to input a wallet address here.
                  </Text>
                </Box>
              )}
            </Box>
          </Loading>
        </Card>
      ))}
      {/* 其他 Groups  */}
      {myAddress && (
        <>
          {huntingList.saved.map((item) => (
            <Card
              key={item.address}
              display={'flex'}
              flexDirection={'row'}
              rounded="23px"
              py="24px"
              bg="#262626"
              pl={'26px'}
              pr={'22px'}
              mb={3}
            >
              {/* NOTE: 為了不壓扁圖片，暫時用 Box 代替 */}
              {item.img_url ? (
                <Box
                  backgroundImage={item.img_url}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  width="52px"
                  height="52px"
                  mr={3}
                  rounded="5px"
                />
              ) : (
                <Avatar
                  className="hunting-list-avatar"
                  _before={{
                    content: `""`,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    bgColor: `#${item.address?.replace('0x', '').substring(0, 6)}`,
                    opacity: '0.5',
                    rounded: '5px',
                  }}
                  width="52px"
                  height="52px"
                  mr={3}
                  // bgImage="/images/default_avatar.png"
                  size="xs"
                  // src={_data.avatar}
                  src={'/images/default_avatar.png'}
                  rounded="5px"
                />
              )}

              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexGrow={'1'}>
                <Box>
                  <Text>{getEllipsisTxt(item.address)}</Text>
                  <Text>{item.name}</Text>
                </Box>
                <Image
                  src="/images/icon/toGroup.png"
                  cursor={'pointer'}
                  onClick={() => handleGroupClick(item.address)}
                />
              </Box>
            </Card>
          ))}
        </>
      )}
      {isOpen && (
        <MsgPop
          isOpen={isOpen}
          onClose={onClose}
          isDemo={false}
          targetAddress={targetAddress}
          targetImage={targetImage}
        />
      )}
      {isBuyOpen && (
        <ConsumerBuySpot
          showMessage={isBuyOpen}
          onShowMessageClose={onBuyClose}
          onShowMessageOpen={onBuyOpen}
          targetUser={targetUser}
          sendEmptyMessage={sendEmptyMessage}
        />
      )}
    </Box>
  );
};

export default SideInfoHuntingList;
