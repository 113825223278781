import { AxiosInstance } from 'axios';

export const getPointsInfo = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/point/info/`, {});

  return response?.data;
};
export const getConsumerReferralCode = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/point/referral_code/`, {});

  return response?.data;
};
export const getConsumerReferralList = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/point/referrals/`, {});

  return response?.data;
};

export const getSpecificConsumerPointsInfo = async (users: any[], instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/enterprise/point/consumer_info/`, { users });

  return response?.data;
};

export const getEnterprisePointsInfo = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.get(`/wallets/enterprise/point/info/`, {});

  return response?.data;
};

export const getOtherWalletPoints = async (address: any, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/point/info/${address}/`, { address });

  return response?.data;
};

export const whoHuntYouOut = async (instance?: AxiosInstance, wallet?: string) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/who_hunted_you/`, { ...(wallet && { address: wallet }) });

  return response?.data;
};

export const occupiedSpots = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/occupied_spots/`, {});

  return response?.data;
};

export const spotsOrders = async (offset: number, limit: number, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/spot_orders/`, { offset, limit });

  return response?.data;
};

export const buySpots = async (instance: AxiosInstance, address: string, order_id: string) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/buy_spot/`, { address, order_id });

  return response?.data;
};
export const sellSpots = async (
  instance: AxiosInstance,
  spot_owner_address: string,
  price: number,
  occupied_spot: number,
) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/sell_spot/`, {
    spot_address: spot_owner_address,
    price,
    occupied_spot,
  });

  return response?.data;
};

export const deleteSpot = async (instance: AxiosInstance, order_id: string, seller_id: string) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/revoke_spot/`, {
    order_id,
    seller_id,
  });

  return response?.data;
};

export const trendingSpots = async (instance: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }

  const response = await instance.post(`/wallets/trending_spots/`, {});
  const spots = response?.data?.spots;

  if (!spots || !Array.isArray(spots)) {
    console.log('Invalid response data');
    return undefined;
  }

  const otherWalletPointsPromises = spots.map((collection: any) => getOtherWalletPoints(collection.address, instance));

  const otherWalletPoints = await Promise.all(
    otherWalletPointsPromises.map((promise) =>
      promise.catch((error) => {
        console.error('Error occurred:', error);
        return null; // Return a default value or null for failed promises
      }),
    ),
  );

  const data = spots.map((collection: any, index: number) => ({
    ...collection,
    ...(otherWalletPoints[index] !== null ? otherWalletPoints[index] : {}), // Only merge successful results
  }));
  return data;
};
