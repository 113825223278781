import React, { ChangeEvent, useRef, useState, useMemo } from 'react';
import {
  Button,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Input,
  useToast,
  InputGroup,
  InputRightElement,
  useClipboard,
  Box,
  Image,
  useBoolean,
  Tooltip,
  Card,
  Progress,
  Divider,
  Center,
  ModalFooter,
} from '@chakra-ui/react';
import Link from 'next/link';

import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { updateConsumerProfile } from '../../../pages/api/consumer_profile';
import { useBluwhale } from '@/context/BluwhaleContext';
import { useConsumerInfo } from '../../../redux/useConsumerInfo';
import { basicConfig } from '@/configs';
import axios from 'axios';
import { cutEllipsisTxtWithLength, getEllipsisTxt } from '@/utils/format';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useEnterprisePoints } from '../../../redux/useEnterprisePoints';
import LinkTest from '../test/LinkTest';
interface ShareModallProps {
  showShareModal: boolean;
  onShareModalOpen: () => void;
  onShareModalClose: () => void;
}
function ProfileModal({ showShareModal, onShareModalOpen, onShareModalClose }: ShareModallProps) {
  const title = 'Bluwhale';
  const toast = useToast();
  const [flag, setFlag] = useBoolean();

  const { user, axiosInstance, onLogout } = useBluwhale();

  const { refetch: refetchUserInfo } = useConsumerInfo();
  const [image, setImage] = useState<File | null>(null);

  const uploadImageInputRef = useRef<HTMLInputElement>(null);

  const { consumerPoints, consumerReferralCode, consumerReferralUser } = useSelector(
    (state: RootState) => state.consumerPoints,
  );
  const consumerInfo = useSelector((state: RootState) => state.consumerInfo);
  const { data: enterprisePoints, refetch, status } = useEnterprisePoints();
  const enterpriseDetails = useSelector((state: RootState) => state.enterpriseDetail.enterpriseDetail);
  const { project_favicon_url, owned_project, wallet_amount, project_url, project_web_title } = enterpriseDetails || {};
  const {
    avaiable_spots: emptySeats,
    available_rewards = 0,
    current_level = 1,
    next_level_spots,
    queued_users = 0,
    refferals = 0,
    total_rewards = 0,
    total_spots: totalSeats,
    required_referrals = 0,
    unclaimed_points = 0,
    total_points = 0,
    previous_referrals = 0,
  } = consumerPoints ?? {};
  const shareUrl = `test`;
  const [editingName, setEditingName] = useState(consumerInfo?.display_name || project_web_title);

  const { onCopy } = useClipboard(user?.address || '');
  const copyCode = async () => {
    await onCopy();

    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };

  const handleEditingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditingName(event?.target?.value);
  };
  const updateConsumerName = async () => {
    await updateConsumerProfile(editingName, axiosInstance);
    await refetchUserInfo();
    toast({
      description: `Name updating successfully`,
      status: 'success',
      position: 'top',
    });
  };
  const uploadAvatar = async (selectedImage: File | null) => {
    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    const bearerToken = token ? `Bearer ${token}` : undefined;
    if (!selectedImage) {
      console.error('No image selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedImage);

      // Replace 'upload_url' with the actual URL to your server endpoint
      const response = await axios.post(`${basicConfig.bluwhale.walletAPIUrl}/wallets/update_avatar/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${bearerToken}`,
        },
      });
      await refetchUserInfo();
      toast({
        description: `Image uploaded successfully`,
        status: 'success',
        position: 'top',
      });
      console.log('Image uploaded successfully:', response.data);
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      setImage(null);
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);

      // Trigger uploadAvatar function after setting the image
      uploadAvatar(selectedImage);
    }
  };

  const levelPercentage = useMemo(() => {
    if (required_referrals > 0) {
      return Math.floor(((refferals - previous_referrals) / required_referrals) * 100);
    }
    return 0;
  }, [required_referrals, previous_referrals]);

  return (
    <Modal isOpen={showShareModal} scrollBehavior={'inside'} onClose={onShareModalClose} size={['lg', '2xl', '6xl']}>
      <ModalOverlay
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="full"
        h="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
        zIndex="1000"
        backdropFilter="blur(10px)"
      />
      <ModalContent
        backgroundColor={'#000'}
        borderWidth={1}
        borderColor="linear-gradient(55deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))"
        rounded="2xl"
        display={'flex'}
        justifyContent={'center'}
        py={4}
        px={4}
        minH={500}
        bg="rgba(47, 47, 47, 0.6)"
      >
        <ModalHeader fontWeight="bold" display={'flex'} flexDir={'column'} justifyItems={'center'}>
          <Text textAlign={'left'} fontWeight={'bold'} fontSize={42}>
            Account Profile
          </Text>
          {/* {`Upgrade to Level ${(current_level || 1) + 1}!`} */}
          <ModalCloseButton mt={2} />
        </ModalHeader>
        <Box
          width={'100%'}
          h={0.5}
          bg={
            'linear-gradient(to right, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0)) '
          }
        />

        <ModalBody position={'relative'} my={8}>
          <Flex flexDir={'row'} justifyItems={'flex-start'} justifyContent={'center'} position={'relative'} gap={10}>
            {/* avatar */}
            <Box position={'relative'} flex={1} minW={120}>
              <Image
                src={consumerInfo?.photo_url || project_favicon_url || '/images/default_avatar.png'}
                h="20"
                w="20"
                borderRadius={100}
                mb={user?.user_type === 'enterprise' ? 4 : 0}
              />
              {user?.user_type === 'enterprise' ? (
                <Flex gap={2} flexDir={'column'}>
                  <Flex justifyContent={'flex-start'} alignItems={'center'} gap={2} alignSelf={'flex-start'}>
                    {' '}
                    <Image src={'/images/icon/rewards.png'} w={18} h={18} />
                    <Text fontSize={'xl'} alignSelf={'flex-start'} color={'#ffffff'} fontWeight={700}>
                      {consumerPoints?.total_rewards?.toFixed(2) || enterprisePoints?.balance?.toFixed(2) || '0.00'}
                    </Text>
                  </Flex>
                  <Flex alignItems={'center'} alignSelf={'flex-start'}>
                    <Text alignSelf={'flex-start'} fontSize={12} color="rgba(172, 172, 172, 1)">
                      BLUAI Balance
                    </Text>
                    <Tooltip
                      placement="auto"
                      label={
                        <Image
                          src={
                            user?.user_type === 'enterprise'
                              ? '/images/nnterprise_overlay.png'
                              : '/images/consumer_overlay.png'
                          }
                        />
                      }
                      maxW={558}
                      maxH={326}
                      bg="transparent"
                    >
                      <div className="flex items-center font-bold text-lg">
                        <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
                      </div>
                    </Tooltip>
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}
              {user?.user_type === 'consumer' ? (
                <Image
                  position={'absolute'}
                  left={14}
                  top={14}
                  src={'/images/icon/avatar_edit.png'}
                  h="5"
                  w="5"
                  borderRadius={36}
                  mb={4}
                  cursor={'pointer'}
                  onClick={() => {
                    if (uploadImageInputRef.current) {
                      uploadImageInputRef.current.click();
                    }
                  }}
                />
              ) : (
                <></>
              )}
              <Input ref={uploadImageInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />{' '}
            </Box>
            <Flex flexDir={'column'}>
              {user?.user_type === 'consumer' ? (
                <Box
                  display={'flex'}
                  flexDir={['column', 'column', 'row']}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={4}
                >
                  {/* informations */}{' '}
                  <Card
                    variant="outline"
                    color={'#979797'}
                    p={2}
                    px={4}
                    alignItems={'center'}
                    w={285}
                    display={'flex'}
                    gap={2}
                    borderWidth={0}
                    bg="rgba(217, 217, 217, 0.05)"
                  >
                    <Flex justifyContent={'space-between'} alignItems={'center'} gap={2} alignSelf={'flex-start'}>
                      {' '}
                      <Image src={'/images/icon/rewards.png'} w={18} h={18} />
                      <Text fontSize={'xl'} alignSelf={'flex-start'} color={'#ffffff'} fontWeight={700}>
                        {consumerPoints?.total_rewards?.toFixed(2) || enterprisePoints?.balance?.toFixed(2)}
                      </Text>
                    </Flex>

                    <Flex alignItems={'center'} alignSelf={'flex-start'}>
                      <Text alignSelf={'flex-start'} fontSize={12} color="rgba(172, 172, 172, 1)">
                        BLUAI Balance
                      </Text>
                      <Tooltip
                        placement="auto"
                        label={<Image src={'/images/consumer_overlay.png'} />}
                        maxW={558}
                        maxH={326}
                        bg="transparent"
                      >
                        <div className="flex items-center font-bold text-lg">
                          <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
                        </div>
                      </Tooltip>
                    </Flex>
                  </Card>
                  {/* level */}
                  <Card
                    variant="outline"
                    color={'#979797'}
                    p={2}
                    px={4}
                    alignItems={'center'}
                    display={'flex'}
                    gap={2}
                    w={285}
                    borderWidth={0}
                    bg="rgba(217, 217, 217, 0.05)"
                    justifyContent={'flex-start'}
                  >
                    <Flex flexDir={'column'} w="100%">
                      <Flex alignSelf={'flex-start'} justifyContent={'space-between'} w="90%">
                        <Box>
                          <Text fontSize={24} alignSelf={'center'} color={'#ffffff'} fontWeight={700}>
                            Level {current_level}
                          </Text>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                          <Text fontSize={12} alignSelf={'center'} color={'#ffffff'} fontWeight={700} opacity={0.5}>
                            Next level +{next_level_spots} spots
                          </Text>
                        </Box>
                      </Flex>

                      <Flex justifyItems={'center'} width={[200, '20vw', '20vw']} maxW={280}>
                        <Flex flexDir={'row'} alignItems={'center'} gap={1} position={'relative'}>
                          <Flex justifyItems={'center'} width={[200, '20vw', '20vw']} maxW={200} alignItems={'center'}>
                            <Box flex={1} borderRadius={100} mt={1}>
                              <Progress
                                sx={{
                                  '& > div': {
                                    background: '#6235D0',
                                  },
                                }}
                                size="sm"
                                value={levelPercentage}
                                borderRadius={100}
                              />
                            </Box>
                            <Text fontSize={12} ml={1} opacity={1} color={'#fff'} fontWeight={'bold'}>
                              Level{current_level + 1}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Card>
                  {/* rewards */}
                  <Card
                    variant="outline"
                    color={'#979797'}
                    p={2}
                    px={4}
                    alignItems={'center'}
                    display={'flex'}
                    gap={2}
                    w={285}
                    borderWidth={0}
                    bg="rgba(217, 217, 217, 0.05)"
                    justifyContent={'flex-start'}
                  >
                    <Flex flexDir={'column'} w="100%">
                      <Flex alignSelf={'flex-start'} justifyContent={'space-between'} w="90%">
                        <Text fontSize={24} alignSelf={'center'} color={'#ffffff'} fontWeight={700}>
                          Rewards
                        </Text>
                      </Flex>
                      <Flex justifyItems={'center'} width={[200, '20vw', '20vw']} maxW={280}>
                        <Flex flexDir={'row'} alignItems={'center'} gap={1} position={'relative'}>
                          <Flex justifyItems={'center'} width={[200, '20vw', '20vw']} maxW={200} alignItems={'center'}>
                            <Box flex={1} borderRadius={100} mt={1}>
                              <Progress
                                sx={{
                                  '& > div': {
                                    background: '#24FF00',
                                  },
                                }}
                                size="sm"
                                value={total_rewards}
                                borderRadius={100}
                              />
                            </Box>
                            <Text fontSize={12} ml={1} opacity={1} color={'#fff'} fontWeight={'bold'}>
                              {total_points + unclaimed_points}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Card>
                </Box>
              ) : (
                <></>
              )}
              <Flex
                flexDir={['column', 'column', 'row']}
                justifyContent={'space-around'}
                my={user?.user_type === 'enterprise' ? 0 : [4, 4, 10]}
                gap={[10, 10, 0]}
              >
                {/* input messages */}
                <Flex flexDir={'column'} gap={4}>
                  <Flex flexDir={'column'} gap={2}>
                    <Text fontWeight={'bold'} ml={2}>
                      {user?.user_type === 'enterprise' ? 'Project Name' : 'Username'}
                    </Text>
                    <Input
                      w={400}
                      type={'text'}
                      borderRadius={100}
                      border={'1px solid rgba(255, 255, 255, 0.2)'}
                      bg="rgba(217, 217, 217, 0.1)"
                      value={editingName}
                      onChange={handleEditingChange}
                      isDisabled={user?.user_type === 'enterprise'}
                      // _disabled={{
                      //   opacity: '1',
                      //   bg: 'rgba(217, 217, 217, 0.1)', // Adjust background color to match non-disabled state
                      //   border: '1px solid rgba(255, 255, 255, 0.2)', // Adjust border color to match non-disabled state
                      //   cursor: 'auto', // Ensure cursor stays as default in disabled state
                      // }}
                      maxLength={42}
                    />
                  </Flex>
                  <Flex flexDir={'column'} gap={2}>
                    <Text fontWeight={'bold'} ml={2}>
                      Email Address
                    </Text>
                    <Input
                      w={400}
                      type={'text'}
                      borderRadius={100}
                      border={'1px solid rgba(255, 255, 255, 0.2)'}
                      bg="rgba(217, 217, 217, 0.1)"
                      value={user?.email}
                      isDisabled={true}
                      // _disabled={{
                      //   opacity: '1',
                      //   bg: 'rgba(217, 217, 217, 0.1)', // Adjust background color to match non-disabled state
                      //   border: '1px solid rgba(255, 255, 255, 0.2)', // Adjust border color to match non-disabled state
                      //   cursor: 'auto', // Ensure cursor stays as default in disabled state
                      // }}
                    />
                  </Flex>
                  {user?.user_type === 'consumer' ? (
                    <Flex flexDir={'column'} gap={2} position={'relative'}>
                      <Text fontWeight={'bold'} ml={2}>
                        Wallet Address
                      </Text>
                      <Input
                        w={400}
                        type={'text'}
                        borderRadius={100}
                        border={'1px solid rgba(255, 255, 255, 0.2)'}
                        bg="rgba(217, 217, 217, 0.1)"
                        value={user?.address}
                        fontSize={12}
                        isDisabled={true}
                        // _disabled={{
                        //   opacity: '1',
                        //   bg: 'rgba(217, 217, 217, 0.1)', // Adjust background color to match non-disabled state
                        //   border: '1px solid rgba(255, 255, 255, 0.2)', // Adjust border color to match non-disabled state
                        //   cursor: 'auto', // Ensure cursor stays as default in disabled state
                        // }}
                      />
                      <Image
                        className="cursor-pointer hover:bg-purple-600"
                        position={'absolute'}
                        alt="copy"
                        src="/ic_copy.png"
                        width={5}
                        height={5}
                        display={'inline-block'}
                        onClick={copyCode}
                        right={4}
                        top={10}
                      />
                    </Flex>
                  ) : (
                    <></>
                  )}
                  {user?.user_type === 'enterprise' ? (
                    <Flex flexDir={'column'} gap={2}>
                      <Text fontWeight={'bold'} ml={2}>
                        Links
                      </Text>
                      <Input
                        w={400}
                        type={'text'}
                        borderRadius={100}
                        border={'1px solid rgba(255, 255, 255, 0.2)'}
                        bg="rgba(217, 217, 217, 0.1)"
                        value={project_url}
                        isDisabled={true}
                        // _disabled={{
                        //   opacity: '1',
                        //   bg: 'rgba(217, 217, 217, 0.1)', // Adjust background color to match non-disabled state
                        //   border: '1px solid rgba(255, 255, 255, 0.2)', // Adjust border color to match non-disabled state
                        //   cursor: 'auto', // Ensure cursor stays as default in disabled state
                        // }}
                      />
                    </Flex>
                  ) : (
                    <></>
                  )}
                </Flex>

                <Center height={250} display={['flex']} flex={1} mx={[2, 4, 6]}>
                  <Divider orientation="vertical" color="#fff" />
                </Center>
                {/* social connections */}
                <Flex flexDir={'column'}>
                  <Text fontWeight={'bold'}>Social Connections</Text>
                  <Text color={'#7C7C7C'}> Help to verify your account by connecting your social account</Text>
                  <Box my={4}>
                    <LinkTest />
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
        <Box
          width={'100%'}
          h={0.5}
          bg={
            'linear-gradient(to right, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0)) '
          }
        />
        <ModalFooter px={[8]}>
          <Flex justifyContent={'space-between'} w={'full'}>
            <Flex>
              <Box
                className="hover:bg-[#313131] py-4 px-4 text-md md:text-xl font-bold cursor-pointer rounded-lg flex gap-3"
                onClick={async () => {
                  await onLogout();
                }}
              >
                <Image transform={'rotate(270deg)'} src="/ic-log-out.svg" cursor={'pointer'} /> Sign out
              </Box>
              <Link
                href="https://t.me/+-8FiECKBiBo4MTk1"
                passHref
                legacyBehavior
                className="hover:bg-[#313131] py-6 px-4 text-md md:text-xl font-bold cursor-pointer rounded-lg flex gap-3 items-center"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:bg-[#313131] py-4 px-4 text-md md:text-xl font-bold cursor-pointer rounded-lg flex gap-3 items-center"
                >
                  <Image src="/images/icon/mail.png" cursor={'pointer'} w={[2, 5]} h={[2, 5]} />{' '}
                  <Text fontWeight={'bold'}>Contact Us</Text>
                </a>
              </Link>
            </Flex>

            <Flex gap={[2, 4, 8]}>
              {/* <Button
                //onClick={handleLinkTwitter}
                colorScheme="purple"
                bg="#fff"
                rounded="full"
                color="#6235D0"
                fontWeight="bold"
                px={8}
                py={4}
                alignSelf={'center'}
                cursor={'pointer'}
                fontSize={16}
                _hover={{
                  color: '#cf4c54',
                  bg: '#343148',
                }}
              >
                Delete Account
              </Button> */}
              {user?.user_type === 'consumer' ? (
                <Button
                  //onClick={handleLinkTwitter}
                  colorScheme="purple"
                  bg="#6235D0"
                  rounded="full"
                  color="#fff"
                  fontWeight="bold"
                  px={8}
                  py={4}
                  alignSelf={'center'}
                  cursor={'pointer'}
                  onClick={updateConsumerName}
                  fontSize={16}
                >
                  Update Profile
                </Button>
              ) : null}
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ProfileModal;
