import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import { changeConsumerName, changeConsumerPhoto, restoreConsumerInfo } from './consumerInfo';
import { useBluwhale } from '@/context/BluwhaleContext';
import { fetchUserInfo } from '../pages/api/enterpriseapi';
import { auth } from '@/configs/firebase';
export const useConsumerInfo = () => {
  const [status, setStatus] = useState('idle');
  const { user, axiosInstance } = useBluwhale();

  const dispatch = useDispatch<AppDispatch>();
  const consumerInfo = useSelector((state: RootState) => state.consumerInfo);
  const updateConsumerName = (name: string) => {
    dispatch(changeConsumerName(name));
  };

  const restoreInfo = () => {
    dispatch(restoreConsumerInfo());
  };
  const updateConsumerPhoto = (photo: string) => {
    dispatch(changeConsumerPhoto(photo));
  };

  async function fetchData() {
    try {
      if (!getEnable() || !user?.address) {
        return undefined;
      }
      setStatus('loading');
      const result = await fetchUserInfo(user?.address);
      setStatus('succeeded');
      if (result?.display_name) {
        await updateConsumerName(result?.display_name);
      }
      if (result?.photo_url) {
        await updateConsumerPhoto(result?.photo_url);
      }
    } catch (error) {
      setStatus('failed');
      console.error('Error fetching campaign list:', error);
    }
  }

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && user?.user_type === 'enterprise') || false;
  }

  useEffect(() => {
    if (status === 'idle' && user?.user_type === 'consumer') {
      fetchData();
    }
  }, [status, user?.user_type]);

  const refetch = async () => {
    fetchData();
  };
  return { data: consumerInfo, status, refetch, restoreInfo };
};
