import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Card,
  Image,
  Avatar,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { cutEllipsisTxtWithLength, isURL, getEllipsisTxt } from '@/utils/format';
import { useAccount } from 'wagmi';
import { useBluwhale } from '@/context/BluwhaleContext';
import { basicConfig } from '@/configs';
import { buySpots, getOtherWalletPoints } from '../../../../pages/api/consumer_points';
import { ConsumerPointsInfo } from '../../../../types';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { useFetchOccupiedSpots } from '@/hooks/useFetchOccupiedSpots';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface ConsumerBuySpotModalProps {
  showMessage: boolean;
  onShowMessageOpen: () => void;
  onShowMessageClose: () => void;
  refetchList?: () => void;
  sendEmptyMessage?: () => void;
  targetUser?: any;
  hideLeft?: boolean;
  editingPrice?: boolean;
}
const ConsumerBuySpot = ({
  showMessage,
  onShowMessageOpen,
  onShowMessageClose,
  targetUser,
  refetchList,
  sendEmptyMessage,
  hideLeft,
  editingPrice,
}: ConsumerBuySpotModalProps) => {
  const { user, onLogout, axiosInstance } = useBluwhale();
  const toast = useToast();
  const [targetUserPoints, setTargetUserPoints] = useState<ConsumerPointsInfo | undefined>(undefined);
  const { data: spots, status, refetch: refetchOccupiedSpots } = useFetchOccupiedSpots();
  const fetchTargetUserPoints = async () => {
    if (user) {
      const result = await getOtherWalletPoints(targetUser?.address, axiosInstance);
      setTargetUserPoints(result);
    }
  };

  const deferRefetchSpotList = async () => {
    setTimeout(() => {
      refetchList?.();
      refetchOccupiedSpots();
    }, 1000);
  };

  const buySumbit = async () => {
    if (axiosInstance && targetUser?.order_id) {
      try {
        event(events.buy_market_spot_confirm, {
          target_user: targetUser?.display_name,
          target_address: `.${targetUser?.address}.`,
        });
        const result = await buySpots(axiosInstance, targetUser?.address, targetUser?.order_id);
        toast({
          description: 'Successfully bought spot',
          // description: (e as { message: string })?.message,
          status: 'success',
          position: 'top',
        });
        deferRefetchSpotList();
        onShowMessageClose();
      } catch (e: any) {
        if (e?.response?.status === 400) {
          // export const ERROR_MSG = {
          //   SOME_USER_NOT_EXIST: 'some userids do not exist',
          //   NO_SPOT_OCCUPIED: 'no spot occupied',
          //   USER_NOT_EXIST: 'userId does not exist',
          //   MUST_BE_CONSUMER: 'only consumer can recieve msg',
          //   PARAM_INVALID: 'param invalid',
          //   USER_EXIST: 'user exists',
          //   MUST_BE_ENTERPRISE: 'must be enterprise',
          //   MESSAGE_HAVE_READ: 'message have read',
          //   READ_ERROR: 'read error',
          //   BALANCE_ERROR: 'not enough points',
          //   SEND_ONLY_ONE_MSG: 'a user can send only one message to a consumer',
          //   NOT_IN_QUEUE: 'not in queue',
          //   IS_FIRST_IN_QUEUE: 'is first in queue',
          //   ALREDY_IN_QUEUE: 'alredy in queue',
          //   EMPTY_QUEUE: 'empty queue',
          // };

          // export const ERROR_CODE_DETAIL = {
          //   NO_SPOT_OCCUPIED: 'E02',
          //   MUST_BE_CONSUMER: 'E03',
          //   USER_EXIST: 'E04',
          //   MUST_BE_ENTERPRISE: 'E05',
          //   MESSAGE_HAVE_READ: 'E06',
          //   READ_ERROR: 'E07',
          //   BALANCE_ERROR: 'E08',
          //   SEND_ONLY_ONE_MSG: 'E09',
          //   NOT_IN_QUEUE: 'E10',
          //   IS_FIRST_IN_QUEUE: 'E11',
          //   ALREDY_IN_QUEUE: 'E12',
          //   EMPTY_QUEUE: 'E13',
          // };
          toast({
            description: capitalizeFirstLetter(e?.response?.data?.detail?.message),
            status: 'error',
            position: 'top',
          });
          return undefined;
        } else {
          toast({
            description: 'Something wrong, please try again',
            status: 'error',
            position: 'top',
          });
          return undefined;
        }
      }
    } else {
      toast({
        description: 'Successfully bought spot',
        status: 'success',
        position: 'top',
      });
      sendEmptyMessage?.();
      onShowMessageClose();
    }
  };
  useEffect(() => {
    if (targetUser?.address) {
      fetchTargetUserPoints();
    }
  }, [targetUser?.address]);

  return (
    <>
      <Modal isOpen={showMessage} onClose={onShowMessageClose}>
        <ModalOverlay
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          w="full"
          h="100%"
          backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
          zIndex="1000"
        />
        <ModalContent
          backgroundColor={'#000'}
          border={'1px solid #82FCD3'}
          rounded="2xl"
          minH={500}
          display={'flex'}
          justifyContent={'center'}
          minW={[357, 600, 840]}
          ml={['5%', null, null]}
          p={[4, null, 10]}
        >
          <ModalHeader fontWeight="bold" display={'flex'} flexDir={'column'}>
            {' '}
            <Heading mx="auto" mb="4" textAlign={'center'}>
              Buy Spot
            </Heading>
          </ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody display={'flex'} flexDir={'column'} justifyContent={'center'}>
            <Box alignSelf={'center'} flexDir={'column'} borderRadius={10} justifyContent={'center'} display={'flex'}>
              <Image
                w={12}
                h={12}
                src={targetUser?.photo_url || '/images/default_avatar.png'}
                rounded="full"
                key={targetUser?.display_name}
                alignSelf={'center'}
              />
              <Text fontWeight={'bold'} fontSize={16} textAlign={'center'} mt={4}>
                {targetUser?.display_name
                  ? targetUser?.display_name.startsWith('0x')
                    ? getEllipsisTxt(targetUser?.display_name, 3)
                    : targetUser?.display_name
                  : cutEllipsisTxtWithLength(targetUser?.address, 18)}
              </Text>
            </Box>
            <Flex alignSelf={'center'} flexDir={'row'} justifyItems={'center'} justifyContent={'space-between'}>
              <Box
                my={4}
                alignSelf={'center'}
                p={6}
                py={3}
                bg={'rgba(48, 48, 48, 0.65)'}
                borderRadius={10}
                w={240}
                justifyContent={'space-between'}
                display="flex"
                fontSize={14}
                position="relative"
              >
                <Box position={'relative'} w={10}>
                  <Image w={6} src={'/images/icon/seat.png'} />
                  <Text fontSize={12} position={'absolute'} bottom={1} left={2.5}>
                    {' '}
                    {targetUser?.occupied_spot}
                  </Text>
                </Box>
                <Text
                  color={'red'}
                  display={hideLeft || targetUserPoints?.avaiable_spots === 0 || !targetUserPoints ? 'none' : 'block'}
                >
                  Only {targetUserPoints?.avaiable_spots} left!
                </Text>
              </Box>
            </Flex>
            <Flex
              borderRadius={10}
              bg={'rgba(48, 48, 48, 0.65)'}
              alignSelf={'center'}
              flexDir={'column'}
              justifyItems={'center'}
              justifyContent={'space-between'}
            >
              <Box my={2} alignSelf={'center'} p={6} py={2} w={240} justifyContent={'space-between'} display="flex">
                <Box alignSelf={'center'} opacity={0.6}>
                  <Text>Price</Text>
                </Box>
                <Text color={'#fff'}>{targetUser?.price || targetUserPoints?.price} BLUAI</Text>
              </Box>
              <Box my={2} alignSelf={'center'} p={6} py={2} w={240} justifyContent={'space-between'} display="flex">
                <Box alignSelf={'center'} opacity={0.6}>
                  <Text>Fees</Text>
                </Box>
                <Text color={'#fff'}>10%</Text>
              </Box>
            </Flex>
            <Flex justifyContent={'center'} gap={6}>
              <Button
                display="flex"
                alignSelf={'center'}
                alignItems="center"
                fontSize="md"
                fontWeight="bold"
                py={3}
                px={10}
                my={10}
                rounded={'full'}
                bg={'#fff'}
                color="#000"
                flex={1}
                maxW={110}
                onClick={onShowMessageClose}
              >
                Cancel
              </Button>
              <Button
                display="flex"
                alignSelf={'center'}
                alignItems="center"
                fontSize="md"
                fontWeight="bold"
                py={3}
                px={10}
                my={10}
                rounded={'full'}
                bg={'#6235D0'}
                color="#fff"
                flex={1}
                maxW={110}
                onClick={buySumbit}
              >
                Confirm
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ConsumerBuySpot;
