import { fetchClaimStatus } from './userClaimStatusSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import { useBluwhale } from '@/context/BluwhaleContext';

export const useFetchUserClaimStatus = (wallet_address?: string) => {
  const { onLogout, user, axiosInstance } = useBluwhale();

  const dispatch = useDispatch<AppDispatch>();
  const result = useSelector((state: RootState) => state.userClaimStatus.result);
  const status = useSelector((state: RootState) => state.userClaimStatus.status);
  const error = useSelector((state: RootState) => state.userClaimStatus.error);

  useEffect(() => {
    if (status === 'idle' && user?.address && axiosInstance) {
      dispatch(fetchClaimStatus({ myAddress: user?.address, instance: axiosInstance }));
    }
  }, [status, dispatch, user?.address, axiosInstance]);

  const refetch = useCallback(() => {
    if (user?.address && axiosInstance) {
      dispatch(fetchClaimStatus({ myAddress: user?.address, instance: axiosInstance }));
    }
  }, [dispatch, user?.address, axiosInstance]);

  return { result, status, error, refetch };
};
