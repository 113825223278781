import React, { useMemo } from 'react';
import {
  Text,
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  useToast,
  useClipboard,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  TableContainer,
} from '@chakra-ui/react';
import { getEllipsisTxt } from '@/utils/format';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useRouter } from 'next/router';

interface ShareModallProps {
  showShareModal: boolean;
  onShareModalOpen: () => void;
  onShareModalClose: () => void;
}
function FollowerModal({ showShareModal, onShareModalOpen, onShareModalClose }: ShareModallProps) {
  const shareUrl = 'https://profile.bluwhale.com/wallet/connect?from=btn_connectwallet';
  const title = 'Bluwhale';
  const toast = useToast();
  const router = useRouter();

  const { consumerPoints, consumerReferralCode, consumerReferralUser } = useSelector(
    (state: RootState) => state.consumerPoints,
  );

  const { users } = consumerReferralUser || [];
  const { onCopy } = useClipboard(shareUrl);
  const copyCode = async () => {
    await onCopy();
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };
  const Tag = ({ tag }: { tag: string }) => {
    if (tag === 'twitter') {
      return <Image src="/images/icon/twitter.png" w={6} h={6} />;
    }
    if (tag === 'google') {
      return <Image src="/images/icon/google.png" w={6} h={6} />;
    }
    return <></>;
  };
  const goToUserDetail = (address: string) => {
    router.push(`/wallet/${address}`);
    onShareModalClose();
  };
  return (
    <Modal isOpen={showShareModal} onClose={onShareModalClose}>
      <ModalOverlay
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="full"
        h="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
        zIndex="1000"
      />
      <ModalContent
        backgroundColor={'#000'}
        border={'1px solid #82FCD3'}
        rounded="2xl"
        minH={500}
        display={'flex'}
        justifyContent={'center'}
        minW={[357, 600, 1000]}
        ml={['5%', null, null]}
      >
        <ModalHeader fontWeight="bold" display={'flex'} flexDir={'column'}>
          {' '}
          <Heading mx="auto" textAlign={'center'} mt={6}>
            {`Followers`}
          </Heading>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody px={[2, null, 10]} pb={16} minH={600}>
          <TableContainer position="relative" maxH="400px" whiteSpace="pre-wrap" overflowX="auto">
            <Table
              overflowY="scroll"
              size="sm"
              variant="unstyled"
              __css={{ 'table-layout': 'fixed', width: 'full' }}
              position="relative"
            >
              <Thead bgColor="#343434" p="1" color="#a2a2a2" top={0} zIndex="docked" w="100%" position="sticky">
                <Tr>
                  <Th fontSize={[6, null, 12]}>Followers</Th>

                  <Th fontSize={[6, null, 12]}>Social Media</Th>
                  <Th fontSize={[6, null, 12]}>Spots</Th>
                  <Th fontSize={[6, null, 12]}>Verification Level</Th>
                  <Th fontSize={[6, null, 12]}>Rewards</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users &&
                  users?.length > 0 &&
                  users.map((item, index) => (
                    <Tr key={`table${item.address}${index}`}>
                      <Td
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                        cursor={'pointer'}
                        onClick={() => goToUserDetail(item?.address)}
                      >
                        <Image
                          w={8}
                          h={8}
                          src={item?.photo_url || '/images/default_avatar.png'}
                          rounded="full"
                          key={item?.address + index}
                        />
                        <Text textDecoration="underline">
                          {item?.display_name
                            ? //@ts-ignore
                              item?.display_name.startsWith('0x')
                              ? //@ts-ignore
                                getEllipsisTxt(item?.display_name, 3)
                              : //@ts-ignore
                                getEllipsisTxt(item?.display_name, 3)
                            : //@ts-ignore
                              cutEllipsisTxtWithLength(item?.address, 18)}
                        </Text>
                      </Td>

                      <Td>
                        <Flex gap={2} w={50}>
                          {item?.socials?.map((tag, index) => (
                            <Tag tag={tag?.name} />
                          ))}
                        </Flex>
                      </Td>
                      <Td>
                        {item?.spot && (
                          <Flex justifyItems={'center'} gap={2}>
                            <Image src={'/images/icon/seat.png'} w={18} h={18} />
                            <Text color="#00FFD1">{item?.spot}</Text>
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        <Text color="#00FFD1" w={50}>
                          {' '}
                          Level {item?.level}
                        </Text>
                      </Td>
                      <Td>
                        <Text color="#55CCFF" w={50} textAlign={'right'}>
                          {' '}
                          {item?.reward_percentage * 100}%
                        </Text>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FollowerModal;
