import { occupiedSpots } from './../../pages/api/consumer_points';
import { useBluwhale } from '@/context/BluwhaleContext';
import router from 'next/router';
import { auth } from '@/configs/firebase';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { setOccupiedSpotsList, hideCelebrate, occupiedInitialized } from '../../redux/occupiedSpots';
export function useFetchOccupiedSpots() {
  const { onLogout, user: bluUser, onSetUser, axiosInstance } = useBluwhale();
  const [status, setStatus] = useState('idle');
  const dispatch = useDispatch<AppDispatch>();
  const [initialized, setInitialized] = useState(false); // State to track initialization
  const [initialDataReceived, setInitialDataReceived] = useState(false); // State to track if initial data has been received

  const [previousSpotsLength, setPreviousSpotsLength] = useState(0); // State to store the length of the previous spots list

  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;

  const { spotslist, initialized: reduxInitialized } = useSelector((state: RootState) => state.occupiedSpots);

  const changeSpotsList = useCallback(
    (details: any) => {
      dispatch(setOccupiedSpotsList(details));
      if (!initialDataReceived) {
        // Set initialDataReceived to true after the first setOccupiedSpotsList call
        setInitialDataReceived(true);
      }
      if (!reduxInitialized) {
        dispatch(occupiedInitialized());
      }
    },
    [dispatch, setOccupiedSpotsList, occupiedInitialized],
  );

  const hideCelebration = useCallback(() => {
    dispatch(hideCelebrate());
  }, [dispatch, hideCelebrate]);
  async function getData() {
    try {
      if (!getEnable() || !user) {
        return undefined;
      }
      setStatus('loading');

      const { spots } = await occupiedSpots(axiosInstance);
      changeSpotsList(spots?.reverse());

      setStatus('idle'); // Reset status after successful data retrieval
      setPreviousSpotsLength(spots.length); // Update the length of the previous spots list
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // handle 401 error here
        //TODO : refresh token
        onLogout();
        router.push('/login');
      }
      return Promise.reject(error);
    }
  }
  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && bluUser?.user_type === 'consumer') || false;
  }

  const refetch = () => {
    getData();
  };

  useEffect(() => {
    if (initialized && user) {
      setInitialized(true);

      getData();
    }
  }, [initialized, user]);

  return { data: spotslist, status, refetch, hideCelebration };
}
