import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Button,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Popover,
  Image,
  Text,
  Divider,
  Card,
  Collapse,
  Tooltip,
  useBoolean,
  useToast,
  Input,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { MoralisLogo, NavBar } from 'components/elements';
import { useAccount, useDisconnect, useEnsName, useBalance } from 'wagmi';
import { cutEllipsisTxtWithLength, getEllipsisTxt } from 'utils/format';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { basicConfig } from '@/configs';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useFetchUserClaimStatus } from '../../../../redux/useFetchUserClaimStatus';
import { useClaimProfit } from '../../../../redux/useClaimProfit';
import { useBluwhale } from '@/context/BluwhaleContext';
import axios from 'axios';
import { useGetEnterpriseDetail } from '../../../../redux/useGetEnterpriseDetail';
import { useGetEnterpriseProjectInfo } from '../../../../redux/useGetEnterpriseProjectInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useEnterprisePoints } from '../../../../redux/useEnterprisePoints';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useConsumerPoints } from '../../../../redux/useConsumerPoints';
import { fetchEnterpriseClaimPoints } from '../../../../pages/api/enterpriseapi';
import { useConsumerInfo } from '../../../../redux/useConsumerInfo';
import { updateConsumerProfile } from '../../../../pages/api/consumer_profile';
import ProfileModal from '../ProfileModal';

/* eslint-disable */
interface Props {
  couldConnect?: boolean;
  test?: boolean;
  useSpots: boolean;
}
const Header: React.FC<Props> = (props) => {
  const { couldConnect = true, useSpots } = props;
  const router = useRouter();
  const { user: bluwhaleUser } = useBluwhale();
  const { isOpen: showShareModal, onOpen: onShareModalOpen, onClose: onShareModalClose } = useDisclosure();

  const { address: loadedAddress } = router?.query ?? {};
  const { address } = useAccount();
  const { user, onLogout, axiosInstance, firebaseUser } = useBluwhale();
  const { user_type } = user ?? {};
  const enterpriseDetails = useSelector((state: RootState) => state.enterpriseDetail.enterpriseDetail);
  const { data: enterprisePoints, refetch, status } = useEnterprisePoints();
  const consumerInfo = useSelector((state: RootState) => state.consumerInfo);

  const { refetch: refetchUserInfo } = useConsumerInfo();

  const [image, setImage] = useState<File | null>(null);
  const uploadImageInputRef = useRef<HTMLInputElement>(null);

  const [editingName, setEditingName] = useState(consumerInfo?.display_name);
  const [flag, setFlag] = useBoolean();
  const toast = useToast();

  const {
    data: consumerpointsInfo,
    refetch: refetchConsumerPoints,
    status: consumerPointsStatus,
  } = useConsumerPoints();
  const { consumerPoints } = consumerpointsInfo ?? {};

  const { data: projectInfo } = useGetEnterpriseProjectInfo();

  const { project_favicon_url, owned_project, wallet_amount } = enterpriseDetails || {};

  const { result, refetch: refetchUserClaim } = useFetchUserClaimStatus();

  const [displayEnteprisePoints, setDisplayEnterprisePoints] = useState(0);
  const { claimed: hasReward, reward_blu: rewardBlu, reward_usd: rewardUsd } = result ?? {};
  const {
    data: ensName,
    isError,
    isLoading,
  } = useEnsName({
    address,
  });
  const { data: balance } = useBalance({
    address,
  });

  const { disconnect } = useDisconnect({
    onSuccess(data) {
      console.log('Success', data);
    },
  });
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const handleMyWalletClick = async () => {
    await router.push(`/wallet/${bluwhaleUser?.address || bluwhaleUser?.display_name}`);
    router.reload();
  };
  const handleConsumerSigninClick = () => {
    event(events.connectwallet_display, {
      source: 'btn_connectwallet',
    });
    const currentPath = router.asPath;
    let path = `/consumer/login?type=web3&from=${currentPath}`;

    router.push(path);
  };

  const handleShowMySite = async () => {
    router.push(`/enterprise`);
  };

  const getEnterprisePoints = async () => {
    const { increased_point }: { increased_point: number } = await fetchEnterpriseClaimPoints(axiosInstance);
    setDisplayEnterprisePoints(increased_point);
  };
  const uploadAvatar = async (selectedImage: File | null) => {
    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    const bearerToken = token ? `Bearer ${token}` : undefined;
    if (!selectedImage) {
      console.error('No image selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedImage);

      // Replace 'upload_url' with the actual URL to your server endpoint
      const response = await axios.post(`${basicConfig.bluwhale.walletAPIUrl}/wallets/update_avatar/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${bearerToken}`,
        },
      });
      await refetchUserInfo();
      toast({
        description: `Image uploaded successfully`,
        status: 'success',
        position: 'top',
      });
      console.log('Image uploaded successfully:', response.data);
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      setImage(null);
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);

      // Trigger uploadAvatar function after setting the image
      uploadAvatar(selectedImage);
    }
  };

  const handleEditingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditingName(event?.target?.value);
  };

  const updateConsumerName = async () => {
    setFlag.off();
    await updateConsumerProfile(editingName, axiosInstance);
    await refetchUserInfo();
    toast({
      description: `Name updating successfully`,
      status: 'success',
      position: 'top',
    });
  };

  useEffect(() => {
    if (flag) {
      setEditingName(consumerInfo?.display_name);
    }
  }, [flag]);

  useEffect(() => {
    if (address) {
      refetchUserClaim();
    }
  }, [address]);

  const closeProfit = () => {
    if (displayEnteprisePoints) {
      getEnterprisePoints();
    }
  };

  useEffect(() => {
    let timer: any = null;
    if (displayEnteprisePoints) {
      let timer = setTimeout(() => {
        getEnterprisePoints();
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [displayEnteprisePoints]);

  useEffect(() => {
    if (user?.user_type === 'enterprise') {
      getEnterprisePoints();
    }
  }, [user?.user_type]);

  const getUserName = () => {
    if (consumerInfo?.display_name) {
      return consumerInfo?.display_name?.includes?.('0x')
        ? getEllipsisTxt(user?.display_name)
        : consumerInfo?.display_name;
    }
    if (ensName) {
      return `@${ensName}`;
    }
    if (!user?.display_name) {
      return getEllipsisTxt(address);
    }
    if (user?.display_name?.includes('0x')) {
      return getEllipsisTxt(user?.display_name);
    }
    return user?.display_name;
  };
  const userName = getUserName();
  const { onCopy, hasCopied } = useClipboard(userName || '');

  const copyCode = async () => {
    await onCopy();
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };
  return (
    <Box px="6" py="8">
      <Flex align="center" justify="space-between">
        <Link href={basicConfig.bluwhale.officialUrl} className="bg-black z-50">
          <MoralisLogo />
        </Link>
        <NavBar />
        {domLoaded && couldConnect && (isMobile || useSpots) && (
          <HStack gap={'10px'} zIndex={40} display={'flex'} flexDir={'column'}>
            {address || user ? (
              <Flex flexDir={'column'} gap={4}>
                <Button
                  colorScheme="purple"
                  bg="#6235D0"
                  rounded="full"
                  color="#fff"
                  fontSize="xs"
                  fontWeight="bold"
                  p="0"
                  px={['4', null, '6']}
                  onClick={onShareModalOpen}
                >
                  <>
                    <Box
                      position="relative"
                      boxSize="24px"
                      rounded="20px"
                      bgImage={consumerInfo?.photo_url || project_favicon_url || '/images/default_avatar.png'}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgPosition="center center"
                      bgSize="cover"
                      bgBlendMode=""
                      mr="2"
                    />
                    {cutEllipsisTxtWithLength(userName)}
                  </>
                </Button>
              </Flex>
            ) : (
              <Button
                colorScheme="purple"
                bg="#6235D0"
                rounded="full"
                color="#fff"
                fontSize="xs"
                fontWeight="bold"
                p="0"
                px="8"
                onClick={handleConsumerSigninClick}
              >
                Connect Wallet
              </Button>
            )}
          </HStack>
        )}
      </Flex>
      <ProfileModal
        showShareModal={showShareModal}
        onShareModalClose={onShareModalClose}
        onShareModalOpen={onShareModalOpen}
      />
    </Box>
  );
};

export default Header;
