import React from 'react';
import {
  Button,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Input,
  useToast,
  InputGroup,
  InputRightElement,
  useClipboard,
} from '@chakra-ui/react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
interface ShareModallProps {
  showShareModal: boolean;
  onShareModalOpen: () => void;
  onShareModalClose: () => void;
}
function ShareModal({ showShareModal, onShareModalOpen, onShareModalClose }: ShareModallProps) {
  const title = 'Bluwhale';
  const toast = useToast();
  const { consumerPoints, consumerReferralCode, consumerReferralUser } = useSelector(
    (state: RootState) => state.consumerPoints,
  );
  const {
    avaiable_spots: emptySeats,
    available_rewards = 0,
    current_level,
    next_level_spots,
    queued_users,
    refferals,
    total_rewards = 0,
    required_referrals,
    total_spots: totalSeats,
  } = consumerPoints ?? {};
  const shareUrl = `${window.location.origin}/consumer/login?type=web3&referral=${consumerReferralCode}`;

  const { onCopy } = useClipboard(shareUrl);
  const copyCode = async () => {
    await onCopy();
    event(events.btn_copyShareLink);
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };
  const shareEvent = async (social: string) => {
    event(events.share_by, {
      social: `${social}`,
    });
  };
  return (
    <Modal isOpen={showShareModal} onClose={onShareModalClose}>
      <ModalOverlay
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="full"
        h="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
        zIndex="1000"
      />
      <ModalContent
        backgroundColor={'#000'}
        border={'1px solid #82FCD3'}
        rounded="2xl"
        minH={500}
        display={'flex'}
        justifyContent={'center'}
        minW={[357, 600, 1000]}
        ml={["5%",null,null]}
      >
        <ModalHeader fontWeight="bold" display={'flex'} flexDir={'column'}>
          {' '}
          <Heading mx="auto" textAlign={'center'} mt={6}>
            {`Upgrade to Level ${(current_level || 1) + 1}!`}
          </Heading>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody px={[2, 10, 36]} pb={16}>
          <Text fontWeight="bold" fontSize={22} textAlign={'center'}>
            Invite{' '}
            <Text display="inline-block" color="#00D1FF" fontWeight="bold">
              {required_referrals} friends
            </Text>{' '}
            for up to{' '}
            <Text display="inline-block" color="#00D1FF" fontWeight="bold">
              {next_level_spots} extra spots
            </Text>
          </Text>
          <Text fontSize={18} textAlign={'center'} mt={4}>
            You get more as your friends join and claim their rewards.
            <br /> Every successful referral counts!
          </Text>
          <Flex gap={[6, 4, 10]} justifyContent={'center'} my={12}>
            <TwitterShareButton url={shareUrl} title={title} onClick={() => shareEvent('twitter')}>
              <XIcon size={40} round />
            </TwitterShareButton>
            <FacebookShareButton url={shareUrl} title={title} onClick={() => shareEvent('facebook')}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            {/* <FacebookMessengerShareButton url={shareUrl} title={title}>
              <FacebookMessengerIcon size={40} round={true} />
            </FacebookMessengerShareButton> */}

            <TelegramShareButton url={shareUrl} title={title} onClick={() => shareEvent('telegram')}>
              <TelegramIcon size={40} round={true} />
            </TelegramShareButton>
            <RedditShareButton url={shareUrl} title={title} onClick={() => shareEvent('reddit')}>
              <RedditIcon size={40} round={true} />
            </RedditShareButton>
            <LinkedinShareButton url={shareUrl} title={title} onClick={() => shareEvent('linkedin')}>
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>
          </Flex>
          <InputGroup size="md">
            <Input
              p="6"
              bg="rgba(255, 255, 255,0.2)"
              rounded="lg"
              border="none"
              color="#00FFE0"
              fontSize={12}
              alignSelf={'center'}
              value={shareUrl}
            />
            <InputRightElement width={'auto'} rounded={'full'} right={2} top={1}>
              <Button
                colorScheme="purple"
                bg="#6235D0"
                rounded="full"
                color="#fff"
                fontWeight="bold"
                px={4}
                h={8}
                alignSelf={'center'}
                cursor={'pointer'}
                onClick={copyCode}
              >
                COPY
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text color="rgba(137, 137, 137, 1)" textAlign="center" my="2">
            *Referrals only count when they claimed
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ShareModal;
