import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Card,
  Image,
  Avatar,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  useToast,
  Input,
} from '@chakra-ui/react';
import { cutEllipsisTxtWithLength, isURL, getEllipsisTxt } from '@/utils/format';
import { useAccount } from 'wagmi';
import { useBluwhale } from '@/context/BluwhaleContext';
import { basicConfig } from '@/configs';
import { getOtherWalletPoints, sellSpots } from '../../../../pages/api/consumer_points';
import { ConsumerPointsInfo } from '../../../../types';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface ConsumerSellSpotModalProps {
  showMessage: boolean;
  onShowMessageOpen: () => void;
  onShowMessageClose: () => void;
  refetchList: () => void;
  targetUser: any;
}
const ConsumerSellSpot = ({
  showMessage,
  onShowMessageOpen,
  onShowMessageClose,
  targetUser,
  refetchList,
}: ConsumerSellSpotModalProps) => {
  const { user, onLogout, axiosInstance } = useBluwhale();
  const toast = useToast();
  const [code, setCode] = useState<number | undefined>(undefined);

  const [targetUserPoints, setTargetUserPoints] = useState<ConsumerPointsInfo | undefined>(undefined);
  const fetchTargetUserPoints = async () => {
    if (user) {
      const result = await getOtherWalletPoints(targetUser?.address, axiosInstance);
      setTargetUserPoints(result);
    }
  };
  const ChangeCode = (event: any) => {
    const price = parseInt(event.target.value);
    setCode(price || 0);
  };
  const sellSumbit = async () => {
    if (axiosInstance && code) {
      try {
        const result = await sellSpots(axiosInstance, targetUser?.address, code, targetUser?.occupied_spot);
        await toast({
          description: 'Successfully sold spot',
          // description: (e as { message: string })?.message,
          status: 'success',
          position: 'top',
        });
        refetchList();
        onShowMessageClose();
      } catch (e: any) {
        if (e?.response?.status === 400) {
          // export const ERROR_MSG = {
          //   SOME_USER_NOT_EXIST: 'some userids do not exist',
          //   NO_SPOT_OCCUPIED: 'no spot occupied',
          //   USER_NOT_EXIST: 'userId does not exist',
          //   MUST_BE_CONSUMER: 'only consumer can recieve msg',
          //   PARAM_INVALID: 'param invalid',
          //   USER_EXIST: 'user exists',
          //   MUST_BE_ENTERPRISE: 'must be enterprise',
          //   MESSAGE_HAVE_READ: 'message have read',
          //   READ_ERROR: 'read error',
          //   BALANCE_ERROR: 'not enough points',
          //   SEND_ONLY_ONE_MSG: 'a user can send only one message to a consumer',
          //   NOT_IN_QUEUE: 'not in queue',
          //   IS_FIRST_IN_QUEUE: 'is first in queue',
          //   ALREDY_IN_QUEUE: 'alredy in queue',
          //   EMPTY_QUEUE: 'empty queue',
          // };

          // export const ERROR_CODE_DETAIL = {
          //   NO_SPOT_OCCUPIED: 'E02',
          //   MUST_BE_CONSUMER: 'E03',
          //   USER_EXIST: 'E04',
          //   MUST_BE_ENTERPRISE: 'E05',
          //   MESSAGE_HAVE_READ: 'E06',
          //   READ_ERROR: 'E07',
          //   BALANCE_ERROR: 'E08',
          //   SEND_ONLY_ONE_MSG: 'E09',
          //   NOT_IN_QUEUE: 'E10',
          //   IS_FIRST_IN_QUEUE: 'E11',
          //   ALREDY_IN_QUEUE: 'E12',
          //   EMPTY_QUEUE: 'E13',
          // };
          toast({
            description: capitalizeFirstLetter(e?.response?.data?.detail?.message),
            status: 'error',
            position: 'top',
          });
          return undefined;
        } else {
          toast({
            description: 'Something wrong, please try again',
            // description: (e as { message: string })?.message,
            status: 'error',
            position: 'top',
          });
          return undefined;
        }
      }
    }
  };

  useEffect(() => {
    if (targetUser?.address) {
      fetchTargetUserPoints();
    }
  }, [targetUser?.address]);
  return (
    <Modal isOpen={showMessage} onClose={onShowMessageClose}>
      <ModalOverlay
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="full"
        h="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
        zIndex="1000"
      />
      <ModalContent
        backgroundColor={'#000'}
        border={'1px solid #82FCD3'}
        rounded="2xl"
        minH={500}
        display={'flex'}
        justifyContent={'center'}
        minW={[357, 600, 840]}
        ml={['5%', null, null]}
        p={[4, null, 10]}
      >
        <ModalHeader fontWeight="bold" display={'flex'} flexDir={'column'}>
          {' '}
          <Heading mx="auto" mb="4" textAlign={'center'}>
            Sell My Spot
          </Heading>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody display={'flex'} flexDir={'column'}>
          <Box alignSelf={'center'} py={1} bg={'rgba(48, 48, 48, 0.65)'} borderRadius={10} w={300} p={6}>
            <Box display={'flex'} justifyContent={'space-between'} w="full" justifyItems={'center'}>
              <Box position={'relative'} display={'flex'} alignItems={'center'} gap={4} cursor={'pointer'}>
                <Image
                  w={14}
                  h={14}
                  src={targetUser?.photo_url || '/images/default_avatar.png'}
                  rounded="full"
                  key={targetUser?.display_name}
                />
                <Box position={'absolute'} w={10} h={4} left={10} top={8}>
                  <Image w={6} src={'/images/icon/seat.png'} />
                  <Text fontSize={12} position={'absolute'} bottom={0} left={2}>
                    {' '}
                    {targetUser?.occupied_spot}
                  </Text>
                </Box>
              </Box>
              <Box alignSelf={'center'}>
                <Text fontWeight={'bold'} fontSize={16} textAlign={'left'}>
                  {targetUser?.display_name
                    ? targetUser?.display_name.startsWith('0x')
                      ? getEllipsisTxt(targetUser?.display_name, 3)
                      : cutEllipsisTxtWithLength(targetUser?.display_name, 12)
                    : cutEllipsisTxtWithLength(targetUser?.address, 18)}
                </Text>
                <Text
                  fontWeight={'bold'}
                  fontSize={16}
                  textAlign={'left'}
                  //@ts-ignore
                  color={targetUserPoints?.queued_users > 0 ? 'red' : '#25BFC9'}
                >
                  {' '}
                  {/* @ts-ignore */}
                  {targetUserPoints?.queued_users > 0
                    ? `${targetUserPoints?.queued_users} in line `
                    : //@ts-ignore
                    targetUserPoints?.avaiable_spots > 1
                    ? `${targetUserPoints?.avaiable_spots} spots left`
                    : `Last spot`}
                </Text>
              </Box>
            </Box>
          </Box>
          <Flex alignSelf={'center'} flexDir={'row'} justifyItems={'center'} justifyContent={'space-between'} w={300}>
            <Box alignSelf={'center'} opacity={0.6}>
              <Text>Price</Text>
            </Box>
            <Box
              my={4}
              alignSelf={'center'}
              p={6}
              py={2}
              bg={'rgba(48, 48, 48, 0.65)'}
              borderRadius={10}
              w={200}
              justifyContent={'space-between'}
              display="flex"
              justifyItems={'center'}
            >
              <Input
                flex={1}
                type={'number'}
                color="#25BFC9"
                onChange={ChangeCode}
                value={code}
                border={'none'}
                placeholder="price"
              />
              <Text flex={1} color={'#fff'} textAlign={'center'} alignSelf={'center'}>
                BLUAI
              </Text>
            </Box>
          </Flex>
          <Flex justifyContent={'center'} gap={6}>
            <Button
              display="flex"
              alignSelf={'center'}
              alignItems="center"
              fontSize="md"
              fontWeight="bold"
              py={3}
              px={10}
              my={10}
              rounded={'full'}
              bg={'#fff'}
              color="#000"
              flex={1}
              maxW={110}
              onClick={onShowMessageClose}
            >
              Cancel
            </Button>
            <Button
              display="flex"
              alignSelf={'center'}
              alignItems="center"
              fontSize="md"
              fontWeight="bold"
              py={3}
              px={10}
              my={10}
              rounded={'full'}
              bg={'#6235D0'}
              color="#fff"
              flex={1}
              maxW={110}
              onClick={sellSumbit}
              isDisabled={!code}
            >
              Confirm
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ConsumerSellSpot;
