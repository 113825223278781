/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import { basicConfig } from '@/configs';
import axios, { AxiosInstance } from 'axios';
import { isNil } from 'ramda';
import { CompareAttribute, CompareProjectInfo, CompareHolders, Collection, ActiveUser } from '@/types/group';
import { useState, useEffect } from 'react';
import { TOKEN_COLORS, filterTokenAddrs } from '@/constants/tokens';
import { useBluwhale } from '@/context/BluwhaleContext';

type ActivityUpperTypes = {
  'AGGRESSIVE ACTIVITY': 'aggressive_activity';
  'DEX TRADER': 'dex_trader';
  'ERC20 FLIPPER': 'erc20_flipper';
  'LIQUIDITY PROVIDER': 'liquidity_provider';
  'NFT SWEEPER': 'nft_sweeper';
  'NFT TRADER': 'nft_trader';
  'NFT MINTER': 'nft_minter';
  'NFT CREATOR': 'nft_creator';
  'NFT HOLDER': 'nft_holder';
  'STABLE COIN HOLDER': 'stable_coin_holder';
  'YIELD FARMER': 'yield_farmer';
  'ASSETS MILLIONAIRE': 'assets_millionaire';
  'HEAVY BORROWER': 'heavy_borrower';
};

type ActivityTypes = ActivityUpperTypes & {
  'Aggressive Activity': 'aggressive_activity';
  'Dex Trader': 'dex_trader';
  'ERC20 Flipper': 'erc20_flipper';
  'Liquidity Provider': 'liquidity_provider';
  'NFT Sweeper': 'nft_sweeper';
  'NFT Trader': 'nft_trader';
  'Stable Coin Holder': 'stable_coin_holder';
  'Yield Farmer': 'yield_farmer';
};
const activityMap: ActivityTypes = {
  'AGGRESSIVE ACTIVITY': 'aggressive_activity',
  'DEX TRADER': 'dex_trader',
  'ERC20 FLIPPER': 'erc20_flipper',
  'LIQUIDITY PROVIDER': 'liquidity_provider',
  'NFT SWEEPER': 'nft_sweeper',
  'NFT TRADER': 'nft_trader',
  'NFT MINTER': 'nft_minter',
  'NFT CREATOR': 'nft_creator',
  'NFT HOLDER': 'nft_holder',
  'ASSETS MILLIONAIRE': 'assets_millionaire',
  'STABLE COIN HOLDER': 'stable_coin_holder',
  'YIELD FARMER': 'yield_farmer',
  'HEAVY BORROWER': 'heavy_borrower',
  'Aggressive Activity': 'aggressive_activity',
  'Dex Trader': 'dex_trader',
  'ERC20 Flipper': 'erc20_flipper',
  'Liquidity Provider': 'liquidity_provider',
  'NFT Sweeper': 'nft_sweeper',
  'NFT Trader': 'nft_trader',
  'Stable Coin Holder': 'stable_coin_holder',
  'Yield Farmer': 'yield_farmer',
};
const getColor = (name: string, address?: string) => {
  if (isNil(address)) {
    return '#81EAFF';
  }
  if (filterTokenAddrs.includes(address)) {
    return TOKEN_COLORS[name] || '#81EAFF';
  }
  return `#${address.replace('0x', '').substring(0, 6)}`;
};
const holdersUtil = (collections: Collection[]) => {
  const result = collections.map((collection: Collection) => ({
    ...collection,
    bgColor: getColor(collection.name, collection.address),
    percentage: Math.floor(collection.holders_percentage * 100),
  }));

  return result;
};
const headers = {
  'content-type': 'application/json',
  'X-API-Key': basicConfig.bluwhale.apiKey,
};
export const checkEnterPriseDetail = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`wallets/enterprise/enterprise_detail/`, {});

  console.log('🚀 ~ file: wallet/connect.tsx:60 ~ checkEnterPriseDetail ~ response:', response);
  const holders = response?.data?.holders_also_hold.map((collection: any) => ({
    ...collection,
    bgColor: isNil(collection.address) ? '#81EAFF' : `#${collection.address.replace('0x', '').substring(0, 6)}`,
    percentage: (collection.holders_percentage * 100).toFixed(2),
  }));
  const result = { ...response?.data, holders_also_hold: holders };
  return result;
};

export const checkCreatedGroups = async (address: string) => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/created_groups/`,
    { wallet_address: address },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  //console.log('🚀 ~ file: wallet/connect.tsx:60 ~ checkEnterPriseDetail ~ response:', response);
  return response?.data;
};

export const createEmbedCode = async (address: string, website: string) => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/generate_code/`,
    { wallet_address: address, website_url: website },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const updateEnterpriseUrl = async (address: string, website: string) => {
  const response = await axios.put(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/update_group_info/`,
    { wallet_address: address, website_url: website },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getActiveUser = async (address: string, interval: string): Promise<ActiveUser> => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/active_users/`,
    { contract_address: address, expression: 'daily' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data.active_users;
};

export const getSimilarProject = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/enterprise/similar_projects/`, {});
  return response?.data;
};

export const getProjectInfo = async (wallet: string, key?: string) => {
  const payload = {
    contract_address: wallet,
    time_picker: key || '',
  };

  const config = {
    headers: {
      'content-type': 'application/json',
      'X-API-Key': basicConfig.bluwhale.apiKey,
    },
  };

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/project_info/`,
    payload,
    config,
  );

  const result = response?.data;
  return result;
};

export const getGroupingAttributes = async (wallet: string, key?: string) => {
  const payload = {
    contract_address: wallet,
    time_picker: key || '',
  };
  const config = {
    headers: {
      'content-type': 'application/json',
      'X-API-Key': basicConfig.bluwhale.apiKey,
    },
  };

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/attributes/`,
    payload,
    config,
  );

  const result = response?.data.groups;

  return result;
};

export const getAlsoHoldList = async (wallet: string, key?: string) => {
  const payload = {
    contract_address: wallet,
    time_picker: key || '',
  };

  const config = {
    headers: {
      'content-type': 'application/json',
      'X-API-Key': basicConfig.bluwhale.apiKey,
    },
  };

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/holders_also_hold/`,
    payload,
    config,
  );
  const result = holdersUtil(response?.data.collections);

  return result;
};

// comparison
export const comparisonProjectInfo = async (compare_to: string): Promise<CompareProjectInfo[]> => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/comparison/project_info/
      `,
    { compare_to },
    {
      headers,
    },
  );
  return response?.data?.projects;
};

export const comparisonProjectAttributes = async (compare_to: string): Promise<CompareAttribute[]> => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/comparison/attributes/
      `,
    { compare_to },
    {
      headers,
    },
  );
  return response?.data?.attributes;
};

export const comparisonProjectHolders = async (compare_to: string): Promise<CompareHolders[]> => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/comparison/holders_also_hold/
      `,
    { compare_to },
    {
      headers,
    },
  );
  const holders: CompareHolders[] = response?.data.holders_also_hold.map((collection: any) => ({
    ...collection,
    bgColor: isNil(collection.address) ? '#81EAFF' : `#${collection.address.replace('0x', '').substring(0, 6)}`,
    percentage: (collection.holders_percentage * 100).toFixed(2),
  }));

  return holders;
};

export const comparisonProjectActiveUsers = async (
  expression: string,
  compareAddress?: string,
): Promise<ActiveUser[]> => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/active_users/`,
    { compare_to: compareAddress ? [compareAddress] : [], expression: 'daily' },
    {
      headers,
    },
  );
  return response?.data.active_users;
};

export const completionEmail = async (address: string, email: string) => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/completion_email/`,
    { contract_address: address, email },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getTokenProfile = async (address: string, key?: string) => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/token_profiles/`,
    { contract_address: address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getBuyingPower = async (address: string, key?: string) => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/buying_power/`,
    { contract_address: address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const claimingProject = async (contract_address: string) => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/claim_project/`,
    { contract_address },
    {
      headers,
    },
  );
  return response?.data;
};

export type GroupingUsersType = {
  total: number;
  users: Array<{ address: string; ens: string }>;
};
export const getGroupingUsers = async (
  contract_address: string,
  page = 1,
  limit = 100,
  key = '',
): Promise<GroupingUsersType> => {
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/users/`,
    { contract_address, page, limit, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getEnterpriseCompaign = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }

  const response = await instance.get(`${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/campaigns/`, {});
  return response?.data;
};

export const sendComapaign = async (campaign_id: string, users: string[], instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/send_campaign_to_users/`,
    { campaign_id, users },
  );
  return response?.data;
};

export const useCreateCampaign = () => {
  const [campaignData, setCampaignData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const createCampaign = async (
    buttonText: string,
    buttonUrl: string,
    description: string,
    imgUrl: string,
    wallet_address: string,
    axiosInstance: AxiosInstance,
  ) => {
    if (!axiosInstance) {
      console.log('instance is null');
      return undefined;
    }
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/create_campaign/`,
        {
          button_text: buttonText,
          button_url: buttonUrl,
          description,
          img_url: imgUrl,
          wallet_address,
        },
      );

      setCampaignData(response?.data);
      return response?.data;
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { campaignData, isLoading, error, createCampaign };
};

export const addUserToOutReachList = async (users: string[], source?: string) => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/add_wallets_to_outreach_list/`,
    { users, source },
    {
      headers,
    },
  );
  return response?.data;
};

export const getOutReachList = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.get(`${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/outreach_users/`, {});
  return response?.data?.users;
};

export const getSubUser = async (contract_address: string, subgroup_id: string, key?: string) => {
  if (!subgroup_id) {
    return;
  }
  const id = activityMap[subgroup_id as keyof ActivityTypes];
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/subgroup/${id}/users/`,
    { contract_address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getSubActiveUser = async (contract_address: string, subgroup_id: string, key?: string) => {
  if (!subgroup_id) {
    return;
  }

  const id = activityMap[subgroup_id as keyof ActivityTypes];

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/subgroup/${id}/active_users/`,
    { contract_address, expression: 'daily', time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};
export const getSubHolders = async (contract_address: string, subgroup_id: string, key?: string) => {
  const id = activityMap[subgroup_id as keyof ActivityTypes];

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/subgroup/${id}/holders_also_hold/`,
    { contract_address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  const result = holdersUtil(response?.data.collections);

  return result;
};

export const getSubTokenProfile = async (contract_address: string, subgroup_id: string, key?: string) => {
  const id = activityMap[subgroup_id as keyof ActivityTypes];

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/subgroup/${id}/token_profiles/`,
    { contract_address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getSubBuyingPower = async (contract_address: string, subgroup_id: string, key?: string) => {
  const id = activityMap[subgroup_id as keyof ActivityTypes];

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/subgroup/${id}/buying_power/`,
    { contract_address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getSubChainUtilization = async (contract_address: string, subgroup_id: string, key?: string) => {
  const id = activityMap[subgroup_id as keyof ActivityTypes];

  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/grouping/subgroup/${id}/chain_utilization/`,
    { contract_address, time_picker: key || '' },
    {
      headers: {
        'content-type': 'application/json',
        'X-API-Key': basicConfig.bluwhale.apiKey,
      },
    },
  );
  return response?.data;
};

export const getCompareTokenProfiles = async (compare_to: string, group_id?: string) => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/comparison/token_profiles/`,
    { compare_to },
    {
      headers,
    },
  );
  return response?.data;
};

export const getCompareBuyingPower = async (compare_to: string, group_id?: string) => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/comparison/buying_power/`,
    { compare_to },
    {
      headers,
    },
  );
  return response?.data;
};

export const getCompareChainUtiliation = async (compare_to: string, group_id?: string) => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/enterprise/comparison/chain_utilization/`,
    { compare_to },
    {
      headers,
    },
  );
  return response?.data;
};

export const getMsgList = async (userAddress: string, targetAddress: string, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.get(`wallets/messages/${userAddress}/${targetAddress}/`, {});
  console.log(response?.data, 12345);

  return response?.data;
};

export const sendMsgList = async (
  userAddress: string,
  targetAddress: string,
  text: string,
  transaction_hash?: string,
  instance?: AxiosInstance,
) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`wallets/messages/${userAddress}/${targetAddress}/`, {
    text,
    ...(transaction_hash && { transaction_hash }),
  });
  return response?.data;
};
export const updateConsumerMessageStatus = async (
  userAddress: string,
  targetAddress: string,
  message_id: string,
  instance?: AxiosInstance,
) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.put(`wallets/messages/${userAddress}/${targetAddress}/read/${message_id}/`, {});
  return response?.data;
};

export const markAllRead = async (userAddress: string, targetAddress: string, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`wallets/messages/${userAddress}/${targetAddress}/mark-all-as-read/`, {});
  return response?.data;
};

export const readCampaign = async (userAddress: string, campaign_id: string, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null readCampaign');
    return undefined;
  }
  const response = await instance.put(`wallets/campaigns/${userAddress}/read/${campaign_id}/`, {});
  return response?.data;
};
export const getCategories = async () => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = user ? JSON.parse(user).accessToken : undefined;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.get(`${basicConfig.bluwhale.walletAPIUrl}/auth/profile_category/`, {
    headers,
  });
  return response?.data;
};
export const updateProfile = async (category: string, project_address?: string, project_url?: string) => {
  const firebaseUser = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const token = firebaseUser ? JSON.parse(firebaseUser).accessToken : undefined;
  const bearerToken = (await token) ? `Bearer ${token}` : undefined;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${bearerToken}`,
  };
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/auth/update_profile/`,
    { category, ...(project_address && { project_address }), ...(project_url && { project_url }) },
    {
      headers,
    },
  );
  return response?.data;
};
export const fetchUserInfo = async (wallet: string) => {
  if (!wallet) {
    return;
  }
  const response = await axios.post(
    `${basicConfig.bluwhale.walletAPIUrl}/wallets/get_info/`,
    { wallet },
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );
  return response?.data;
};

export const fetchMsgFee = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.get(`/wallets/point/fee_rate/`, {});
  return response?.data;
};

export const fetchEnterpriseClaimPoints = async (instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/enterprise/point/increased/`, {});
  return response?.data;
};

export const fetchEnterpriseSimilarWallet = async (label?: string) => {
  if (!label) {
    console.log('instance is null');
    return undefined;
  }
  const response = await axios.post(`${basicConfig.bluwhale.walletAPIUrl}/wallets/get_similar_wallets_by_label/`, {
    label,
  });
  return response?.data;
};
