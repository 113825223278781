import { FC, ReactNode } from 'react';
import { Box, Button, Text, Card, Image } from '@chakra-ui/react';
import { getEllipsisTxt, separator } from 'utils/format';
import { useRouter } from 'next/router';
import Loading from '@/components/modules/Loading';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import EmptyState from '@/components/elements/EmptyState';

interface SideInfoRightCheckoutAddressesProps {
  loading: boolean;
  list: any[];
}

const SideInfoRightCheckoutAddresses: FC<SideInfoRightCheckoutAddressesProps> = (props) => {
  const { loading, list } = props;
  const router = useRouter();

  const handleGroupSelect = (item: any) => {
    console.log('handleGroupSelect', item);
    // checkout_address
    event(events.checkout_address, {
      click: item.name,
    });
    const destinationUrl = item.project_type === 'group' ? `/group/${item.address}` : `/home/${item.address}`;
    router.push(destinationUrl);
  };
  return (
    <Box mb="72px">
      <Text fontWeight="500" fontSize="26px" letterSpacing="-1px" mb={6} ml={1}>
        Check out these projects
      </Text>

      <Loading loading={loading} minH="350px">
        <EmptyState isEmpty={list.length === 0}>
          {list.map((item) => (
            <Box
              display="flex"
              flexDirection="row"
              mb="18px"
              w={['full', '15vw', '15vw']}
              cursor="pointer"
              key={item.address}
              onClick={() => handleGroupSelect(item)}
            >
              {/* NOTE: 為了不壓扁圖片，暫時用 Box 代替 */}
              <Box
                backgroundImage={item?.img_url || '/images/default_avatar.png'}
                backgroundSize="cover"
                backgroundPosition="center"
                width={[32, '5vw', '5vw']}
                height={[20, '5vw', '5vw']}
                mr={3}
                rounded="lg"
              />
              <Box w={['full', '5vw', '5vw']}>
                <Text className="truncate" maxW="150px" fontSize="20px">
                  {item.name}
                </Text>
                <Text fontSize="16px" fontWeight="500" w="150px" letterSpacing="-1px" display="inline-block">
                  {getEllipsisTxt(item.address)}
                </Text>

                {item.project_type === 'group' && (
                  <Text fontSize="14px" position="relative">
                    <Text as="span" display="inline-block" mr={2}>
                      {separator(item.wallet_amount)}
                    </Text>
                    <Text as="span" display="inline-block" color="#959595">
                      wallets
                    </Text>
                    {/* <Image src="/images/icon/bookmark.png" display="inline-block" position="absolute" right="0" /> */}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
        </EmptyState>
      </Loading>
    </Box>
  );
};

export default SideInfoRightCheckoutAddresses;
