/* eslint-disable no-inline-comments */
import { Box, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
  text: string;
  parentWidth?: number;

  limit?: number;
  isExpand?: boolean;
}
const TextWithMore = ({ text, isExpand = false, parentWidth }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let limit;
  if (isMobile) {
    limit = isExpand ? 80 : 40;
  } else {
    limit = (parentWidth && Math.floor(parentWidth / 12)) || 60;
  }

  const limitedText = text?.substring(0, limit);
  const isMore = text?.length > limit;
  let maxH;
  if (isMobile) {
    maxH = isExpand ? '50px' : '80px';
  } else {
    maxH = isExpand ? 'auto' : '50px';
  }

  return (
    <Box
      maxH={maxH}
      overflow="auto" // Enable scrolling
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {isExpanded ? text : limitedText + (isMore ? '...' : '')}
      {isMore && (
        <Button variant="link" color={'#00FFF0'} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'less' : 'more'}
        </Button>
      )}
    </Box>
  );
};

export default TextWithMore;
