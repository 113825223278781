import { FC, ReactNode } from 'react';
import { Box, Button, Text, Card, Image } from '@chakra-ui/react';
import { cutEllipsisTxtWithLength, getEllipsisTxt, separator } from 'utils/format';
import { useRouter } from 'next/router';
import Loading from '@/components/modules/Loading';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';

interface SideInfoRightProps {
  loading: boolean;
  list: any[];
  onSeeMoreClick: () => void;
}

const SideInfoRightWhoHuntedYou: FC<SideInfoRightProps> = (props) => {
  const { loading, list, onSeeMoreClick } = props;
  const router = useRouter();
  const handleAddressClick = (address: string) => {
    event(events.whoHuntYou_list, {
      click: `.${address}.`,
    });

    router.push(`/wallet/${address}`);
  };

  return (
    <Box mb="48px">
      <Text fontWeight="500" fontSize="26px" letterSpacing="-1px" mb={6} ml={1}>
        Who viewed your profile
      </Text>
      <Card rounded="23px" py="24px" bg="#262626">
        <Loading loading={loading} minH="400px">
          <Box position="relative">
            {list.map((item, index) => (
              <Box
                key={item.address}
                flexDirection="row"
                display="flex"
                alignItems="center"
                mb="12px"
                pl="29px"
                py="1"
                cursor="pointer"
                filter={index > 1 ? 'blur(4px)' : 'none'}
                blur="4px"
                pointerEvents={index > 1 ? 'none' : 'auto'}
                onClick={() => handleAddressClick(item.address)}
              >
                {item.avatar ? (
                  <Image src={item.avatar} rounded="50%" w="37px" h="37px" mr="20px" shadow="md" />
                ) : (
                  <Box
                    position="relative"
                    mr="4"
                    boxSize="36px"
                    rounded="full"
                    bgImage={item?.photo_url ?? '/images/default_avatar.png'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgPosition="center center"
                    bgSize="cover"
                    bgBlendMode=""
                    _before={{
                      content: `""`,
                      position: 'absolute',
                      width: '36px',
                      height: '36px',
                      bgColor: item?.photo_url ? 'transparent' : `#${item.address.replace('0x', '').substring(0, 6)}`,
                      opacity: '0.5',
                      rounded: 'full',
                    }}
                  />
                )}
                <Text textDecoration="underline" fontSize="17px">
                  {item?.display_name
                    ? item?.display_name?.startsWith('0x')
                      ? getEllipsisTxt(item.display_name)
                      : item?.display_name
                    : getEllipsisTxt(item.address)}
                </Text>
              </Box>
            ))}
          </Box>
          {/* {!loading && (
            <Button
              bgColor="#6235D0"
              w="60%"
              h="36px"
              position="absolute"
              bottom="0"
              left="64px"
              rounded="24px"
              color="#fff"
              fontSize="16px"
              onClick={onSeeMoreClick}
            >
              See more
            </Button>
          )} */}
        </Loading>
      </Card>
    </Box>
  );
};

export default SideInfoRightWhoHuntedYou;
