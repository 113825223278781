import { useBluwhale } from '@/context/BluwhaleContext';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { auth } from '@/configs/firebase';
import {
  setconsumerPoints,
  setconsumerReferralCode,
  setconsumerReferralUser,
  restoreConsumerPoints,
} from './consumerPoints';
import { getConsumerReferralCode, getConsumerReferralList, getPointsInfo } from '../pages/api/consumer_points';

export const useConsumerPoints = () => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;

  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState('idle');
  const { user: BluUser, axiosInstance } = useBluwhale();
  const { consumerPoints, consumerReferralCode, consumerReferralUser } = useSelector(
    (state: RootState) => state.consumerPoints,
  );

  const restorePoints = () => {
    dispatch(restoreConsumerPoints());
  };

  async function fetchData() {
    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    try {
      if (!getEnable()) {
        return undefined;
      }
      setStatus('loading');
      try {
        const pointsInfo = await getPointsInfo(axiosInstance);
        if (pointsInfo) {
          await dispatch(setconsumerPoints(pointsInfo));
        }
      } catch (error: any) {
        throw Error(`get user point userWallet--${BluUser?.address},  userToken--${token}`);
      }
      const { code: referralCode } = await getConsumerReferralCode(axiosInstance);
      const { users } = await getConsumerReferralList(axiosInstance);

      if (referralCode) {
        await dispatch(setconsumerReferralCode(referralCode));
      }
      if (users) {
        await dispatch(setconsumerReferralUser(users));
      }
      setStatus('succeeded');
    } catch (error) {
      setStatus('failed');
      console.error('Error fetching campaign list:', error);
    }
  }

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && BluUser?.user_type === 'consumer') || false;
  }
  useEffect(() => {
    if (!consumerPoints && status === 'idle' && user && BluUser?.user_type === 'consumer') {
      setStatus('loading');
      fetchData();
    }
  }, [consumerPoints, status, BluUser, user]);

  const refetch = async (apiName: 'pointsInfo' | 'referralCode' | 'referralList') => {
    try {
      setStatus('loading');
      if (!(await getEnable())) {
        return;
      }

      let responseData: any; // Adjust the type according to the response data structure
      switch (apiName) {
        case 'pointsInfo':
          responseData = await getPointsInfo(axiosInstance);
          if (responseData) {
            dispatch(setconsumerPoints(responseData));
          }
          break;
        case 'referralCode':
          responseData = await getConsumerReferralCode(axiosInstance);
          if (responseData) {
            dispatch(setconsumerReferralCode(responseData));
          }
          break;
        case 'referralList':
          responseData = await getConsumerReferralList(axiosInstance);
          if (responseData) {
            dispatch(setconsumerReferralUser(responseData));
          }
          break;
        default:
          throw new Error('Invalid API name');
      }

      setStatus('succeeded');
    } catch (error) {
      setStatus('failed');
      console.error('Error refetching data:', error);
    }
  };
  const data = { consumerPoints, consumerReferralCode, consumerReferralUser };
  return { data, status, refetch, restorePoints };
};
