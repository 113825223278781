import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { auth } from '@/configs/firebase';
import { useRouter } from 'next/router';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import {
  Box,
  Flex,
  Image,
  Grid,
  GridItem,
  Wrap,
  WrapItem,
  Text,
  Tag,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useClipboard,
  ModalFooter,
  Input,
  Avatar as ChakraAvatar,
  Divider,
  InputGroup,
  InputRightElement,
  Card,
  Link,
  Checkbox,
  Switch,
} from '@chakra-ui/react';
import { useAccount, useWalletClient } from 'wagmi';
import { parseEther } from 'viem';
import { DemoAvatar } from '@/constants/demoAvatar';
import { useDashBoard } from '@/components/modules/DashBoard/contexts/DashBoardContext';
import { basicConfig, bluPrivateNode } from '@/configs';
import { cutEllipsisTxtWithLength, getEllipsisTxt } from '@/utils/format';
import TextWithMore from '@/components/modules/Enterprise/TextWithMore';
import axios from 'axios';
import { useBluwhale } from '@/context/BluwhaleContext';
import { fetchMsgFee, fetchUserInfo, getMsgList, markAllRead, sendMsgList } from '../../../../pages/api/enterpriseapi';
import { ethers } from 'ethers';
import { ConsumerPointsInfo, Msg } from '../../../../types';
import { useGetConsumerCompaigns } from '@/hooks/useGetConsumerCompaigns';
import { CheckIcon } from '@chakra-ui/icons';
import { OtherInfoType } from '@/components/modules/DashBoard/elements/PlatformUser';
import { useConsumerPoints } from '../../../../redux/useConsumerPoints';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getOtherWalletPoints } from '../../../../pages/api/consumer_points';
import ConsumerBuyBLU from './ConsumerBuyBLU';
import { useClaimPoint } from '../../../../redux/useClaimPoint';
import { useFetchOccupiedSpots } from '@/hooks/useFetchOccupiedSpots';

const isMe = (address1: string, address2: string) => {
  return address1?.toLowerCase() === address2?.toLowerCase();
};

interface MsgProp {
  isOpen: boolean;
  onClose: () => void;
  isDemo: boolean;
  targetAddress: string;
  targetImage: string;
  myAddress?: string;
  enterpriseData?: any;
  isConsumer?: boolean;
  targetUser?: any;
}
const MsgPop = ({
  isOpen,
  onClose,
  isDemo,
  targetAddress,
  targetImage,
  enterpriseData,
  myAddress,
  isConsumer,
  targetUser,
}: MsgProp) => {
  const { user, onLogout, axiosInstance } = useBluwhale();
  const { isOpen: showBuyBLU, onOpen: onShowBuyBLUOpen, onClose: onShowBuyBLUClose } = useDisclosure();
  const { data: spots, status, refetch: refetchOccupiedSpots } = useFetchOccupiedSpots();

  const router = useRouter();
  const { consumerPoints } = useSelector((state: RootState) => state.consumerPoints);
  const { refetch: refetchConsumerPoints } = useConsumerPoints();
  const { points, changePoints } = useClaimPoint();

  const { data: signer } = useWalletClient();
  const toast = useToast();
  const { data, refetch } = useGetConsumerCompaigns();
  const { onCopy } = useClipboard(targetAddress);
  const [isOnChain, setIsOnChain] = useState(false);
  const [messages, setMessages] = useState<Msg[]>([]);
  const [isBuy, setIsBuy] = useState(false);
  const avatarColor = targetAddress?.replace('0x', '').substring(0, 6);
  const targetName = getEllipsisTxt(targetAddress);
  const [input, setInput] = useState('');
  const changeInput = (e: any) => {
    setInput(e.target.value);
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };
  const [loading, setLoading] = useState(false);
  const [othersInfo, setOthersInfo] = useState<OtherInfoType | undefined>(undefined);
  const [otherUserPoints, setOtherUserPoints] = useState<ConsumerPointsInfo | undefined>(undefined);
  const [unclaimedPoints, setUnclaimedPoints] = useState<number | undefined>(undefined);
  const [feeRate, setFeeRate] = useState<number | undefined>(undefined);

  const displayPrice = ((otherUserPoints?.price || 1) * (1 + (feeRate || 0)) * 0.0001)?.toFixed(8);
  async function sendEth(targetAddress: string, text: string) {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const price = displayPrice.toString();
        const amountToSend = ethers.utils.parseEther(price); // Amount of ETH to send (in wei)
        // Encode the string data
        const data = ethers.utils.toUtf8Bytes(`${text} - Sent via Bluwhale`);
        const tx = await signer.sendTransaction({
          to: targetAddress,
          value: amountToSend,
          data: ethers.utils.hexlify(data),
        });
        console.log('Transaction sent:', tx.hash);

        setLoading(false);
        return tx;
      } catch (error: any) {
        const { message } = error;
        const bracketIndex = message.indexOf('[');
        const replaced = message.slice(0, bracketIndex).trim();

        setLoading(false);
        toast({
          description: replaced,
          status: 'error',
          position: 'top',
        });
        console.error('Transaction failed:', error);
      }
    } else {
      setLoading(false);
      console.error('MetaMask not installed');
    }
  }

  const sendOnchainMessage = async () => {
    event(events.btn_msg_sent, {
      type: 'on_chain',
      token: (otherUserPoints?.price || 1) * (1 + (feeRate || 0)),
    });
    const result = await sendEth(basicConfig.bluwhaleWallet, input);
    if (result) {
      try {
        if (!getEnable() || !axiosInstance) {
          return undefined;
        }
        //@ts-ignore
        await sendMsgList(user?.address, targetAddress, input, result?.hash, axiosInstance);
        setInput('');
        await fetchMsgList();
        await refetchConsumerPoints('pointsInfo');
        await fetchPoints();
        scrollToBottom();
        event(events.msg_success, {
          type: 'on_chain',
          token: (otherUserPoints?.price || 1) * (1 + (feeRate || 0)),
        });
      } catch (error: any) {
        event(events.msg_failed, {
          type: 'on_chain',
          token: (otherUserPoints?.price || 1) * (1 + (feeRate || 0)),
        });
        if (error?.response?.status === 401) {
          onLogout();
          router.push('/login');
        }
        throw error;
      }
    }
  };

  const handleSend = async () => {
    if (!input) {
      toast({
        description: 'Please enter your message first',
        status: 'warning',
        position: 'top',
      });
    }
    if (user?.address && input) {
      setLoading(true);
      if (isOnChain) {
        sendOnchainMessage();
      } else if (notEnoughPoints && !isOnChain) {
        onShowBuyBLUOpen();
      } else {
        event(events.btn_msg_sent, {
          type: 'off_chain',
          token: (otherUserPoints?.price || 1) * (1 + (feeRate || 0)),
        });
        try {
          await sendMsgList(user?.address, targetAddress, input, '', axiosInstance);
          event(events.msg_success, {
            type: 'off_chain',
            token: (otherUserPoints?.price || 1) * (1 + (feeRate || 0)),
          });
          if (isBuy) {
            event(events.buy_trending_spot_success, {
              target_user: targetUser?.display_name,
              target_address: `.${targetUser?.address}.`,
            });
          }
        } catch (e: any) {
          event(events.msg_failed, {
            type: 'off_chain',
            token: (otherUserPoints?.price || 1) * (1 + (feeRate || 0)),
          });
        }
        setLoading(false);
        setInput('');
        await fetchMsgList();
        await refetchConsumerPoints('pointsInfo');
        await fetchPoints();

        scrollToBottom();
      }
      refetchOccupiedSpots();
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };
  const fetchMsgList = async () => {
    try {
      if (!getEnable() || !axiosInstance) {
        return undefined;
      }
      if (user?.address && targetAddress) {
        const { messages }: { messages: Msg[] } = await getMsgList(user?.address, targetAddress, axiosInstance);
        //@ts-ignore
        const filteredMessage = messages.filter((msg) => msg.text !== ' ');
        setMessages(filteredMessage);
        const totalSum = messages
          ? messages.reduce((sum: number, message: any) => sum + message?.unclaimed_point, 0)
          : 0;
        setUnclaimedPoints(totalSum);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        onLogout();
        router.push('/login');
      }
      throw error;
    }
  };

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && user?.user_type === 'consumer') || false;
  }
  useEffect(() => {
    if (targetAddress && user?.address && isOpen) {
      fetchMsgList();
    }
  }, [targetAddress, isOpen, user?.address]);

  const fetchPoints = async () => {
    if (user) {
      const result = await getOtherWalletPoints(targetAddress, axiosInstance);
      setOtherUserPoints(result);
    }
  };

  const copyCode = async () => {
    await onCopy();
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };
  const readAll = async (address: string, targetAddress: string) => {
    await markAllRead(address, targetAddress, axiosInstance);
    await refetch();
    await onClose();
  };

  useEffect(() => {
    if (messages.length > 0 && messagesEndRef.current) {
      scrollToBottom();
    }
  }, [messages, messagesEndRef?.current]);

  const getUserInfo = async () => {
    const result: OtherInfoType = await fetchUserInfo(targetAddress);

    setOthersInfo(result);
  };

  const getMsgFee = async () => {
    if (!getEnable() || !axiosInstance) {
      return undefined;
    }
    const { fee_rate }: { fee_rate: number } = await fetchMsgFee(axiosInstance);
    if (fee_rate) {
      setFeeRate(fee_rate);
    }
  };
  useEffect(() => {
    if (isOpen && user) {
      getUserInfo();
      fetchPoints();
      getMsgFee();
      refetchOccupiedSpots();
    }
  }, [targetAddress, isOpen, user]);

  const notEnoughPoints =
    !consumerPoints?.total_rewards ||
    consumerPoints?.total_rewards < 1 ||
    (otherUserPoints?.price &&
      otherUserPoints?.price &&
      consumerPoints?.total_rewards < otherUserPoints?.price * (1 + (feeRate || 0))) ||
    false;

  const depositeBLU = async () => {
    onShowBuyBLUClose();
    sendOnchainMessage();
  };

  const closeMsg = async () => {
    onClose();
    if (unclaimedPoints) {
      await changePoints(`+${unclaimedPoints}`);
    }
    if (targetAddress && user?.address) {
      await readAll(user?.address, targetAddress);
    }
  };

  const closeBuyModal = () => {
    setLoading(false);
    onShowBuyBLUClose();
  };

  useEffect(() => {
    const exist = spots?.find((item: any) => item?.address?.toLowerCase() === targetAddress?.toLowerCase());
    if (exist) {
      setIsBuy(false);
    } else {
      setIsBuy(true);
    }
  }, [spots]);

  if (showBuyBLU) {
    return (
      <ConsumerBuyBLU
        showMessage={showBuyBLU}
        onShowMessageClose={closeBuyModal}
        onShowMessageOpen={onShowBuyBLUOpen}
        depositeBLU={depositeBLU}
        price={otherUserPoints?.price || 1}
        feeRate={feeRate || 0}
        isOnChain={isOnChain}
      />
    );
  }
  const displayName = targetUser?.display_name || othersInfo?.display_name || targetName;
  return (
    <Modal isOpen={isOpen} onClose={closeMsg}>
      <ModalOverlay // Set the overlay to cover the entire viewport
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="100%"
        h="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
        zIndex="1000"
      />
      <ModalContent
        backgroundColor={'#000'}
        border={'1px solid #82FCD3'}
        rounded="2xl"
        minW={[360, 500, 700]}
        minH={500}
      >
        <ModalHeader fontWeight="bolder" fontSize={['3xl', null, '4xl']} paddingBottom="0px" alignSelf={'flex-start'}>
          <Flex gap={2} pt={10} alignItems={'center'}>
            <ChakraAvatar src={targetUser?.photo_url || othersInfo?.photo_url} />
            <Flex flexDir={'column'} alignItems={'center'} alignSelf={'flex-start'}>
              {' '}
              <Text
                fontSize={['xl', null, '2xl']}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                alignSelf={'flex-start'}
              >
                {cutEllipsisTxtWithLength(displayName, 20)}
              </Text>
              <Text
                fontSize={['xl', null, 'xl']}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                opacity={0.7}
                textAlign={'left'}
                alignSelf={'flex-start'}
              >
                {getEllipsisTxt(targetAddress)}
                <Image
                  className="cursor-pointer hover:bg-purple-600"
                  alt="copy"
                  src="/ic_copy.png"
                  width={4}
                  height={4}
                  display={'inline-block'}
                  onClick={copyCode}
                />
              </Text>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            flexDir={'column'}
            h={400}
            overflowY={'scroll'}
            sx={{
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {enterpriseData && (
              <Box
                display={'flex'}
                flexDir={'row'}
                gap={4}
                justifyContent={'space-between'}
                alignItems={'center'}
                h={90}
                flex={1}
                position={'relative'}
                px={10}
                py={4}
              >
                <Box
                  display={'flex'}
                  flexDir={'row'}
                  gap={4}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={10}
                  pb={4}
                  alignSelf={'flex-start'}
                  position={'relative'}
                >
                  <Image
                    objectFit="contain"
                    w={10}
                    h={10}
                    src={enterpriseData.icon}
                    alignSelf={'center'}
                    alt="Caffe Latte"
                    rounded="full"
                  />

                  <Flex flexDir={'column'} flex={1} w={400} alignContent={'flex-start'}>
                    <Text textAlign={'left'} fontSize={20} fontWeight={700} opacity={enterpriseData.isRead ? 1 : 0.6}>
                      {enterpriseData.title}
                    </Text>

                    {enterpriseData.description && (
                      <Box
                        maxW={450}
                        alignSelf={'flex-start'}
                        alignItems="center"
                        fontWeight={700}
                        fontSize={18}
                        opacity={enterpriseData.isRead ? 1 : 0.6}
                      >
                        <TextWithMore text={enterpriseData.description} limit={40} />
                      </Box>
                    )}
                  </Flex>
                  <Image
                    objectFit="contain"
                    w="98px"
                    h="55px"
                    src={enterpriseData.img_url}
                    alignSelf={'center'}
                    alt="Caffe Latte"
                  />

                  <Button
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                    fontWeight="bold"
                    py={3}
                    h={10}
                    w={200}
                    rounded={'full'}
                    bg={'white'}
                    color="#000"
                  >
                    <Link href={enterpriseData.button_url} rel="noopener noreferrer" target="_blank">
                      {enterpriseData.button_text}
                    </Link>
                  </Button>
                  <Box
                    position="absolute"
                    bottom={0}
                    left={10}
                    width="90%"
                    height="1px"
                    bg="rgba(180, 171, 171, 0.66)" // You can change the color to your preference
                  />
                </Box>
              </Box>
            )}

            {!enterpriseData &&
              messages.map((item, index) => (
                <Flex
                  key={index}
                  justifyContent={'flex-start'}
                  justifySelf={'flex-start'}
                  alignSelf={'flex-start'}
                  px={[4, 4, 10]}
                  py={4}
                  gap={4}
                  alignItems={'center'}
                  position={'relative'}
                  w={'full'}
                  ref={index === messages.length - 1 ? messagesEndRef : null}
                >
                  <ChakraAvatar name={item?.sender_id || ''} src={item?.sender_avatar || ''} />
                  <Flex flexDir={'column'} justifyContent={'flex-start'} flex={1} width={'full'}>
                    <Text fontSize={24}>
                      {isMe(item?.sender || '', user?.address || '') ? 'Me' : item?.sender_name}
                    </Text>
                    <Text>{item?.text}</Text>
                  </Flex>
                  {/* @ts-ignore */}
                  {item?.read_status?.find(
                    (singleMsg: any) =>
                      singleMsg?.wallet_address?.toLowerCase() === targetAddress?.toLowerCase() &&
                      item?.sender?.toLowerCase() !== targetAddress?.toLowerCase() &&
                      singleMsg?.read_timestamp,
                  ) && <Image src={'/images/icon/readedmsg.png'} cursor={'pointer'} w={6} h={6} />}
                  {item?.tx_hash && (
                    <Link href={`https://etherscan.io/tx/${item?.tx_hash}`} rel="noopener noreferrer" target="_blank">
                      <Image src={'/images/icon/ethscan.png'} cursor={'pointer'} />
                    </Link>
                  )}
                  <Box
                    position="absolute"
                    bottom={0}
                    left={10}
                    width="90%"
                    height="1px"
                    bg="rgba(180, 171, 171, 0.66)" // You can change the color to your preference
                  />
                </Flex>
              ))}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex flexDir={'column'} w={'full'} justifyContent={'center'} alignItems={'center'}>
            <InputGroup size="md" mx={4}>
              <Input
                pr="4.5rem"
                type={'text'}
                placeholder="-- Enter your message here --"
                bg={'#2d2d2d'}
                onChange={changeInput}
                value={input}
                onKeyDown={handleKeyPress}
              />
              <InputRightElement width="6.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleSend}
                  bg="#6235D0"
                  color={'#fff'}
                  rounded={10}
                  px={16}
                  py={4}
                  right={4}
                  isLoading={loading}
                  leftIcon={
                    <Box
                      objectFit="contain"
                      w={6}
                      h={6}
                      bgImage={isOnChain ? '/images/icon/eth.png' : '/images/icon/message_points.png'}
                      bgSize="cover"
                      bgPosition="center"
                      bgRepeat="no-repeat"
                      position="relative"
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {!isOnChain ? (
                        <Text fontWeight={'bold'} color={'#FFB800'} textAlign={'center'} fontSize={8} mr={1}>
                          {otherUserPoints?.price?.toFixed(0)}
                        </Text>
                      ) : (
                        <></>
                      )}
                    </Box>
                  }
                >
                  {isBuy ? 'Buy Spot' : 'SEND'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent={'space-between'} alignItems={'center'} width={'full'} mt={2}>
              <Flex gap={2} alignItems={'center'}>
                <Switch
                  isChecked={isOnChain}
                  onChange={(e) => {
                    e.preventDefault();
                    setIsOnChain(!isOnChain);
                  }}
                  size={'md'}
                />
                <Text fontSize={[14, null, 20]}>{isOnChain ? 'On-Chain Message' : 'Off-Chain Message'}</Text>
              </Flex>

              <Box>
                {loading ? (
                  isOnChain ? (
                    <Text alignSelf={'flex-end'} color={'#FFB800'}>
                      Please confirm in MetaMask
                    </Text>
                  ) : (
                    <></>
                  )
                ) : !isOnChain ? (
                  <Text alignSelf={'flex-end'}>
                    <Text display={['block', null, 'inline-block']} color={'#00C0EA'}>
                      {otherUserPoints?.price?.toFixed(0)} BLUAI + {(feeRate || 0) * 100}% Fees.{' '}
                    </Text>{' '}
                    You have{' '}
                    <Text
                      display={'inline-block'}
                      color={consumerPoints?.total_rewards && consumerPoints?.total_rewards > 0 ? '#FFB800' : 'red'}
                    >
                      {' '}
                      {consumerPoints?.total_rewards}
                    </Text>{' '}
                    BLUAI points
                  </Text>
                ) : (
                  //@ts-ignore
                  <Text color={'#00C0EA'}>{displayPrice} ETH</Text>
                )}
              </Box>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MsgPop;
