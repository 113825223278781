import { FC, ReactNode } from 'react';
import { Box, Button, Text, Card, Image, Avatar } from '@chakra-ui/react';
import { getEllipsisTxt, separator } from 'utils/format';
import Loading from '@/components/modules/Loading';
import { useRouter } from 'next/router';
import SideInfoHuntingList from './SideInfoHuntingList';

interface SideInfoRightProps {
  myAddress: string;
  huntingList: { saved: any[]; created: any[] };
}

const SideInfoLeft: FC<SideInfoRightProps> = (props) => {
  const { myAddress, huntingList } = props;

  return (
    <Box w={'313px'} minH={'100%'} pt="98px" display="flex" flexDirection="column" zIndex={'1'}>
      <SideInfoHuntingList
        myAddress={myAddress}
        huntingList={huntingList}
        display={{
          base: 'none',
          md: 'block',
        }}
      />
    </Box>
  );
};

export default SideInfoLeft;
