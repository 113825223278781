import { useBluwhale } from '@/context/BluwhaleContext';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { setEnterpriseProjectInfo } from './enterpriseProjectInfo';
import { getProjectInfo } from '../pages/api/enterpriseapi';
import { useGetEnterpriseDetail } from './useGetEnterpriseDetail';

export function useGetEnterpriseProjectInfo() {
  const { onLogout, user: bluUser, axiosInstance } = useBluwhale();
  const [status, setStatus] = useState('idle');
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  // Function to fetch enterprise detail data
  const dispatch = useDispatch<AppDispatch>();
  const enterpriseDetail = useSelector((state: RootState) => state.enterpriseDetail.enterpriseDetail);
  const enterpriseProjectInfo = useSelector((state: RootState) => state.enterpriseProjectInfo.projectInfo);
  const { refetch: refetchEnterprise } = useGetEnterpriseDetail();

  const { owned_project, owned_project_processed, reviewing_claimed_project, project_url, project_favicon_url } =
    enterpriseDetail ?? {};
  const changeEnterpriseProjectInfo = useCallback(
    (details: any) => {
      dispatch(setEnterpriseProjectInfo(details));
    },
    [dispatch, setEnterpriseProjectInfo],
  );
  const getData = async () => {
    if (owned_project && owned_project_processed) {
      setStatus('loading');
      const info = await getProjectInfo(owned_project);

      await changeEnterpriseProjectInfo(info);
      setStatus('success');
    } else if (reviewing_claimed_project) {

      setStatus('loading');
      const info = await getProjectInfo(reviewing_claimed_project);
      await changeEnterpriseProjectInfo(info);

      setStatus('success');
    } else if (project_url) {
      const domain = project_url && new URL(project_url).hostname.split('.')[1];
      const capitalizedDomain = project_url && domain.charAt(0).toUpperCase() + domain.slice(1);

      const projectInfo = {
        img_url: project_favicon_url || bluUser?.photo_url,
        name: capitalizedDomain,
        contract_address: '',
        wallet_amount: '',
      };
      await changeEnterpriseProjectInfo(projectInfo);
      setStatus('success');
    }
  };
  const refetch = async () => {
    await refetchEnterprise();
    await getData();
  };
  useEffect(() => {
    if (!enterpriseProjectInfo?.img_url && enterpriseDetail) {
      getData();
    }
  }, [enterpriseProjectInfo?.img_url, enterpriseDetail]);

  useEffect(() => {
    if (enterpriseDetail) {
      getData();
    }
  }, [enterpriseDetail?.owned_project, enterpriseDetail?.reviewing_claimed_project]);

  return { data: enterpriseProjectInfo, refetch, status };
}
