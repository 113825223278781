import { useColorMode, Image } from '@chakra-ui/react';

const MoralisLogo = () => {
  const { colorMode } = useColorMode();

  return (
    <Image
      src={colorMode === 'dark' ? '/Bluewahle-logo.png' : '/Bluewahle-logo.png'}
      height={'100%'}
      width={[120, null, 180]}
      alt="Moralis"
    />
  );
};

export default MoralisLogo;
