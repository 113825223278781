import { useBluwhale } from '@/context/BluwhaleContext';
import { cutEllipsisTxtWithLength, getEllipsisTxt } from '@/utils/format';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Progress,
  Text,
  useDisclosure,
  Tooltip,
  Input,
  useToast,
  useBoolean,
  Collapse,
  useClipboard,
} from '@chakra-ui/react';

import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { mockSimilarWallets } from '@/mocks/mockData';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import ShareModal from './ShareModal';
import FollowerModal from './FollowerModal';
import { useClaimPoint } from '../../../redux/useClaimPoint';
import Loading from '@/components/modules/Loading';
import { useFetchUserClaimStatus } from '../../../redux/useFetchUserClaimStatus';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import ReactGA4 from 'react-ga4';
import axios from 'axios';
import { basicConfig } from '@/configs';
import { fetchUserInfo } from '../../../pages/api/enterpriseapi';
import { OtherInfoType } from '../modules/DashBoard/elements/PlatformUser';
import { useConsumerInfo } from '../../../redux/useConsumerInfo';
import { updateConsumerProfile } from '../../../pages/api/consumer_profile';
import ConsumerSellSpot from '../constomer/MsgPop/ConsumerSellSpot';
import { useRouter } from 'next/router';
import { occupiedSpots } from '../../../pages/api/consumer_points';
import { useFetchOccupiedSpots } from '@/hooks/useFetchOccupiedSpots';
import ProfileModal from '../modules/ProfileModal';
import { useConsumerPoints } from '../../../redux/useConsumerPoints';

const SeatingChart = ({ emptySeats }: { totalSeats: number; emptySeats: number }) => {
  const renderSeats = () => {
    const seats: JSX.Element[] = [];

    for (let i = 0; i < emptySeats; i++) {
      seats.push(<Image key={i} src={'/images/icon/empty_seat.png'} alt={'Empty Seat'} w={22} h={22} />);
    }

    return seats;
  };

  return (
    <Grid templateColumns="repeat(10, 1fr)" gap={1}>
      {renderSeats()}
    </Grid>
  );
};
interface PointsConsumerInterface {
  useSpots?: boolean;
  setUseSpots?: (isUseSpots: boolean) => void;
}
function PointsConsumer({ useSpots = false, setUseSpots }: PointsConsumerInterface) {
  const toast = useToast();
  const router = useRouter();
  const [selectedTab, setSelectedTab] = useState(0);

  const { user, axiosInstance } = useBluwhale();
  const { onCopy } = useClipboard(user?.address || '');

  const { isOpen: isMsgOpen, onOpen: onMsgOpen, onClose: onMsgClose } = useDisclosure();

  const { isOpen: shareOpen, onOpen: openShareModal, onClose: closeShareModal } = useDisclosure();
  const { isOpen: profileOpen, onOpen: openProfileModal, onClose: closeProfilModal } = useDisclosure();

  const { isOpen: followerOpen, onOpen: openFollowerModal, onClose: closeFollowerModal } = useDisclosure();
  const { points, changePoints } = useClaimPoint();
  const { result, refetch: refetchUserClaim } = useFetchUserClaimStatus();
  const [showOccupy, setShowOccupy] = useState(false);
  const { claimed } = result ?? {};
  const [flag, setFlag] = useBoolean();
  const { consumerPoints, consumerReferralCode, consumerReferralUser } = useSelector(
    (state: RootState) => state.consumerPoints,
  );
  const { spotslist: spots, celebrate } = useSelector((state: RootState) => state.occupiedSpots);
  const consumerInfo = useSelector((state: RootState) => state.consumerInfo);

  const { refetch: refetchOccupiedSpots, hideCelebration } = useFetchOccupiedSpots();
  const { refetch: refetchConsumerPoints } = useConsumerPoints();
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);
  const { refetch: refetchUserInfo } = useConsumerInfo();
  const {
    avaiable_spots: emptySeats,
    available_rewards = 0,
    current_level = 1,
    next_level_spots,
    queued_users = 0,
    refferals = 0,
    total_rewards = 0,
    total_spots: totalSeats,
    required_referrals = 0,
    unclaimed_points = 0,
    total_points = 0,
    previous_referrals = 0,
    spots: mySpots,
  } = consumerPoints ?? {};

  const [image, setImage] = useState<File | null>(null);
  const uploadImageInputRef = useRef<HTMLInputElement>(null);

  const [editingName, setEditingName] = useState(consumerInfo?.display_name);

  const { users } = consumerReferralUser || [];
  const rewardsPercentage = useMemo(() => {
    if (total_rewards > 0 && total_points > 0) {
      return Math.floor((total_rewards / (total_points + unclaimed_points)) * 100);
    }
    return 0;
  }, [total_rewards, total_points]);

  const levelPercentage = useMemo(() => {
    if (required_referrals > 0) {
      return Math.floor(((refferals - previous_referrals) / required_referrals) * 100);
    }
    return 0;
  }, [required_referrals, previous_referrals]);
  const clickMoreSpot = async () => {
    event(events.btn_openMoreSpots, {
      spot: `${emptySeats}/ ${totalSeats}`,
    });

    openShareModal();
  };

  const uploadAvatar = async (selectedImage: File | null) => {
    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    const bearerToken = token ? `Bearer ${token}` : undefined;
    if (!selectedImage) {
      console.error('No image selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedImage);

      // Replace 'upload_url' with the actual URL to your server endpoint
      const response = await axios.post(`${basicConfig.bluwhale.walletAPIUrl}/wallets/update_avatar/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${bearerToken}`,
        },
      });
      await refetchUserInfo();
      toast({
        description: `Image uploaded successfully`,
        status: 'success',
        position: 'top',
      });
      console.log('Image uploaded successfully:', response.data);
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      setImage(null);
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);

      // Trigger uploadAvatar function after setting the image
      uploadAvatar(selectedImage);
    }
  };

  const handleEditingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditingName(event?.target?.value);
  };

  const updateConsumerName = async () => {
    setFlag.off();
    await updateConsumerProfile(editingName, axiosInstance);
    await refetchUserInfo();
    toast({
      description: `Name updating successfully`,
      status: 'success',
      position: 'top',
    });
  };

  const [targetUser, setTargetUser] = useState(undefined);

  const buyPoints = (item: any) => {
    event(events.btn_sell_spot, {
      target_user: item?.display_name,
      target_address: `.${item?.address}.`,
    });
    setTargetUser(item);
    onMsgOpen();
  };

  const gotoFollower = (address?: string) => {
    if (!address) {
      return;
    }
    router.push(`/wallet/${address}`);
  };

  const showMarket = () => {
    event(events.btn_trade_and_earn);
    setUseSpots?.(true);
  };

  const listOccupiedSpots = async () => {
    refetchOccupiedSpots();
    setUseSpots?.(true);
  };
  useEffect(() => {
    ReactGA4.gtag('set', 'user_properties', {
      userId: user?.uuid,
      user_type: user?.user_type,
      social_type: user?.sign_in_provider,
      user_name: user?.email || user?.display_name,
      level: current_level,
    });
  }, [current_level]);

  useEffect(() => {
    if (flag) {
      setEditingName(consumerInfo?.display_name);
    }
  }, [flag]);

  useEffect(() => {
    if (spots) {
      event(events.claimed_spot, {
        holding: `${spots?.length} spots`,
      });
    }
  }, [spots, spots?.length]);

  const spotsListHeight = Math.ceil(spots?.length / 4) * 110;
  const mySpotsListHeight = Math.ceil((mySpots?.length || 0) / 5) * 110;

  const userListHeight = (users && Math.ceil(users?.length / 4) * 110) || 110;

  const copyCode = async () => {
    await onCopy();
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };

  useEffect(() => {
    let timer: any;
    let timer2: any;
    if (celebrate) {
      setShowOccupy(true);
      timer = setTimeout(() => {
        hideCelebration();
      }, 4000);
      timer2 = setTimeout(() => {
        setShowOccupy(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [celebrate]);
  //@ts-ignore
  const seatArray = Array.from({ length: emptySeats }, (_, index) => index);

  return !claimed ? (
    <></>
  ) : (
    <Box
      display={'flex'}
      flexDir={'column'}
      borderWidth={1}
      borderColor="#545454"
      borderRadius="xl"
      alignItems={'center'}
      position={'relative'}
      mb={10}
      pt={4}
      bg={'rgba(66, 66, 66, 0.6)'}
    >
      <Box minH="300" w="full">
        <Box
          width={'98%'}
          rounded={100}
          borderRadius={100}
          background={'transparent'}
          position={'absolute'}
          top={0.2}
          left={1}
        >
          <Progress
            sx={{
              '& > div': {
                background: 'linear-gradient(180deg, #82FCD3 0%, #5CC99C 100%);',
              },
            }}
            size="xs"
            value={levelPercentage}
            borderRadius={100}
          />
        </Box>
        <Flex px={18} justifyContent={'space-between'}>
          {' '}
          <Text opacity={1} fontSize={12}>
            Lv{current_level}
          </Text>
          <Text opacity={0.5} fontSize={12}>
            Lv{current_level + 1}
          </Text>
        </Flex>
        {/* user box */}
        <Box display={'flex'} flexDir={'column'} alignItems={'center'}>
          <Box position={'relative'}>
            {' '}
            <Image
              src={consumerInfo?.photo_url || '/images/default_avatar.png'}
              h="16"
              w="16"
              borderRadius={36}
              mb={2}
            />
            <Image
              position={'absolute'}
              right={0}
              bottom={0}
              src={'/images/icon/avatar_edit.png'}
              h="5"
              w="5"
              borderRadius={36}
              mb={2}
              cursor={'pointer'}
              onClick={() => {
                if (uploadImageInputRef.current) {
                  uploadImageInputRef.current.click();
                }
              }}
            />
            <Input ref={uploadImageInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />{' '}
          </Box>
          {!flag ? (
            <Text fontWeight={'bold'} fontSize={14} position={'relative'}>
              {cutEllipsisTxtWithLength(consumerInfo?.display_name, 20)}{' '}
              <Image
                right={0}
                bottom={0}
                src={'/images/icon/name_edit.png'}
                h="3"
                w="3"
                cursor={'pointer'}
                display={'inline-block'}
                onClick={setFlag.on}
              />
            </Text>
          ) : (
            <Box className="relative  w-full mx-auto z-10" alignItems={'center'}>
              <Input
                fontSize={10}
                bg="rgba(255, 255, 255,1)"
                rounded="full"
                border="none"
                color="#000"
                fontWeight={'bold'}
                onChange={handleEditingChange}
                px={4}
                h={8}
                value={editingName}
              />
              <Button
                right="0"
                position={['relative', null, 'absolute']}
                colorScheme="purple"
                bg="#6235D0"
                rounded="xl"
                color="#fff"
                fontSize="xs"
                fontWeight="bold"
                px={4}
                h={8}
                zIndex={40}
                onClick={updateConsumerName}
              >
                OK
              </Button>
            </Box>
          )}
          <Tooltip
            placement="auto"
            label={user?.address}
            borderRadius="10px"
            bg="#000"
            color="#fff"
            fontSize="12px"
            padding="8px"
            textAlign="center"
            transition="opacity 0.5s ease-in-out"
          >
            <Text color="#747474" fontSize={12}>
              {getEllipsisTxt(user?.address)}
              <Image
                className="cursor-pointer hover:bg-purple-600"
                alt="copy"
                src="/ic_copy.png"
                width={4}
                height={4}
                display={'inline-block'}
                onClick={copyCode}
                mb={1}
                ml={1}
              />
            </Text>
          </Tooltip>
        </Box>

        {/* Rewards */}
        <Flex px={18} flexDir={'column'} alignSelf={'flex-start'} mt={2} mb={6} gap={2}>
          <Flex flexDir={'row'} alignItems={'center'} gap={1} position={'relative'}>
            <Image src={'/images/icon/rewards.png'} w={18} h={18} />

            <Flex justifyItems={'center'} width={[200, '20vw', '20vw']} maxW={280}>
              <Box flex={1} borderRadius={100} mt={1}>
                <Progress
                  sx={{
                    '& > div': {
                      background: 'linear-gradient(180deg, #FFE4C4 0%, #F7931A 100%);',
                    },
                  }}
                  size="sm"
                  value={rewardsPercentage}
                  borderRadius={100}
                />
              </Box>
              <Text fontSize={12} ml={1}>
                <Text color="rgba(255, 184, 0, 1)" display={'inline-block'} fontSize={12} ml={1}>
                  {total_rewards}
                </Text>{' '}
                / {total_points + unclaimed_points}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* Level Spots */}
        <Flex justifyContent={'space-between'} w="full" alignItems={'flex-start'}>
          {/*  Spots */}
          <Flex alignSelf={'flex-start'} flex={1} flexDir={'column'} gap={2}></Flex>
        </Flex>
        <Flex px={18} justifyContent={'space-between'}>
          <Text
            opacity={selectedTab === 0 ? 1 : 0.5}
            cursor={'pointer'}
            onClick={() => {
              setSelectedTab(0);
            }}
            fontSize={12}
            fontWeight={selectedTab === 0 ? 700 : 500}
          >
            SPOTS OWNED
          </Text>
          <Text
            cursor={'pointer'}
            opacity={selectedTab === 1 ? 1 : 0.5}
            onClick={() => {
              setSelectedTab(1);
              refetchConsumerPoints('pointsInfo');
            }}
            fontSize={12}
            fontWeight={selectedTab === 1 ? 700 : 500}
          >
            MY SPOTS
          </Text>
          <Text
            cursor={'pointer'}
            opacity={selectedTab === 2 ? 1 : 0.5}
            onClick={() => {
              setSelectedTab(2);
            }}
            fontSize={12}
            fontWeight={selectedTab === 2 ? 700 : 500}
          >
            FOLLOWERS
          </Text>
        </Flex>
        <Flex px={0} w="full" bg={'rgba(66, 66, 66, 0.6)'} justifyContent={'center'} mt={6}>
          {selectedTab === 0 ? (
            <Box
              minH={127}
              maxH={spotsListHeight + 100}
              mt={6}
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              position={'relative'}
            >
              {celebrate && !showOccupy ? <Image position={'absolute'} src={'/images/celebration2.gif'} /> : <></>}
              <Collapse startingHeight={130} endingHeight={spotsListHeight} in={show} animateOpacity>
                <Grid
                  overflowY={'scroll'}
                  sx={{
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                  position={'relative'}
                  px="auto"
                >
                  {spots?.map((item, index) => (
                    <Box
                      position={'relative'}
                      display={'flex'}
                      flexDir={'column'}
                      alignItems={'center'}
                      gap={4}
                      cursor={'pointer'}
                      maxW={63}
                      h={73}
                      transition="background-color 0.3s ease"
                      borderRadius="md"
                      _hover={{ bg: 'rgba(0, 0, 0, 0.5)' }}
                      //@ts-ignore
                      key={item?.address + index}
                    >
                      <Image
                        w={8}
                        h={8}
                        //@ts-ignore
                        src={item?.photo_url || '/images/default_avatar.png'}
                        rounded="full"
                        //@ts-ignore
                        key={item?.display_name + index}
                        className={index === 0 && showOccupy ? `heartBeat active ` : ''}
                      />
                      {showOccupy && index === 0 ? (
                        <Image position={'absolute'} src={'/images/celebration.gif'} />
                      ) : (
                        <></>
                      )}

                      <Box
                        position={'absolute'}
                        w={4}
                        h={4}
                        left={6}
                        top={4}
                        display={index === 0 && showOccupy ? `none ` : 'block'}
                      >
                        <Image src={'/images/icon/seat.png'} />
                        <Text fontSize={8} position={'absolute'} bottom={0.5} left={1.5}>
                          {' '}
                          {/* @ts-ignore */}
                          {item?.occupied_spot}
                        </Text>
                      </Box>

                      <Text
                        display={index === 0 && showOccupy ? `none ` : 'block'}
                        maxW={63}
                        fontWeight={'bold'}
                        fontSize={12}
                        textAlign={'center'}
                      >
                        {/* @ts-ignore */}
                        {item?.display_name
                          ? //@ts-ignore
                            item?.display_name.startsWith('0x')
                            ? //@ts-ignore
                              getEllipsisTxt(item?.display_name, 3)
                            : //@ts-ignore
                              getEllipsisTxt(item?.display_name, 3)
                          : //@ts-ignore
                            cutEllipsisTxtWithLength(item?.address, 18)}
                      </Text>
                      <Button
                        position="absolute"
                        top={0}
                        left={0}
                        w="100%"
                        h="100%"
                        bg={'rgba(0, 0, 0, 0.5)'}
                        opacity={0}
                        transition="opacity 0.3s ease"
                        _hover={{ opacity: 1 }}
                        onClick={() => buyPoints(item)}
                        borderRadius="md"
                      >
                        <Box
                          bg="rgba(98, 53, 208, 1)"
                          rounded="xl"
                          border="1px solid rgba(202, 202, 202, 1)"
                          px="4"
                          py="2"
                          borderRadius="full"
                        >
                          {' '}
                          <Text
                            color={'white'}
                            fontWeight={'bold'}
                            fontSize={12}
                            textAlign={'center'}
                            bg="rgba(98, 53, 208, 1)"
                          >
                            Sell
                          </Text>
                        </Box>
                      </Button>
                    </Box>
                  ))}
                </Grid>
              </Collapse>
              <Image
                src={`/images/icon/down.png`}
                transform={show ? 'rotate(180deg)' : 'none'}
                onClick={handleToggle}
                mt={2}
                mb={10}
                cursor={'pointer'}
                display={spotsListHeight > 110 ? 'block' : 'none'}
              />

              <Button
                bg="linear-gradient(180deg, #82FCD3 0%, #5CC99C 100%)"
                rounded="full"
                color="#000"
                fontSize="14"
                fontWeight="700"
                w={260}
                h={22}
                p={4}
                onClick={showMarket}
                bottom={8}
                alignSelf={'flex-end'}
                mx="auto"
              >
                Trade & Earn
              </Button>
            </Box>
          ) : selectedTab === 1 ? (
            <Box
              minH={162}
              maxH={400}
              position={'relative'}
              mt={6}
              display={'flex'}
              justifyContent={'flex-start'}
              justifyItems={'center'}
              flexDir={'column'}
            >
              <Box flex={1}>
                <Collapse startingHeight={130} endingHeight={mySpotsListHeight} in={show} animateOpacity>
                  <Grid
                    overflowY={'scroll'}
                    sx={{
                      '::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                    templateColumns="repeat(5, 1fr)"
                    gap={2}
                    position={'relative'}
                    justifyItems="center"
                  >
                    {mySpots?.map((item, index) => (
                      <Box
                        position={'relative'}
                        display={'flex'}
                        flexDir={'column'}
                        alignItems={'center'}
                        gap={2}
                        cursor={'pointer'}
                        maxW={63}
                        h={73}
                        transition="background-color 0.3s ease"
                        borderRadius="md"
                        //@ts-ignore
                        key={item?.address + index}
                        onClick={() => gotoFollower(item?.address)}
                      >
                        <Image
                          minW={6}
                          maxW={8}
                          maxH={8}
                          minH={6}
                          //@ts-ignore
                          src={item?.photo_url || '/images/default_avatar.png'}
                          rounded="full"
                          //@ts-ignore
                          key={item?.display_name + index}
                          _hover={{ transform: 'scale(1.2)' }} // Scale on hover
                        />

                        <Box
                          position={'absolute'}
                          w={4}
                          h={4}
                          left={6}
                          top={4}
                          display={index === 0 && showOccupy ? `none ` : 'block'}
                        >
                          <Image src={'/images/icon/seat.png'} />
                          <Text fontSize={8} position={'absolute'} bottom={0.5} left={1.5}>
                            {' '}
                            {/* @ts-ignore */}
                            {item?.occupied_spot}
                          </Text>
                        </Box>

                        <Text maxW={40} fontWeight={'bold'} fontSize={10} textAlign={'center'}>
                          {/* @ts-ignore */}
                          {item?.display_name
                            ? //@ts-ignore
                              item?.display_name.startsWith('0x')
                              ? //@ts-ignore
                                getEllipsisTxt(item?.display_name, 3)
                              : //@ts-ignore
                                getEllipsisTxt(item?.display_name, 3)
                            : //@ts-ignore
                              cutEllipsisTxtWithLength(item?.address, 18)}
                        </Text>
                      </Box>
                    ))}
                    {seatArray.map(
                      (
                        _,
                        index, // Using index as key
                      ) => (
                        <Image
                          key={index}
                          src={'/images/icon/empty_seat.png'}
                          alt={'Empty Seat'}
                          w={6}
                          h={6}
                          alignSelf={'flex-start'}
                          mt={2}
                        />
                      ),
                    )}
                  </Grid>
                </Collapse>
                <Image
                  src={`/images/icon/down.png`}
                  transform={show ? 'rotate(180deg)' : 'none'}
                  onClick={handleToggle}
                  mt={2}
                  mb={10}
                  cursor={'pointer'}
                  display={mySpotsListHeight > 110 ? 'block' : 'none'}
                  mx="auto"
                />
              </Box>

              <Button
                bg="linear-gradient(180deg, #82FCD3 0%, #5CC99C 100%)"
                rounded="full"
                color="#000"
                fontSize="14"
                fontWeight="700"
                w={260}
                h={22}
                p={4}
                onClick={clickMoreSpot}
                bottom={8}
                alignSelf={'flex-end'}
                mx="auto"
              >
                Open More Spots
              </Button>
            </Box>
          ) : (
            <Box
              minH={127}
              maxH={400}
              mt={6}
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Collapse startingHeight={130} endingHeight={userListHeight} in={show} animateOpacity>
                <Grid
                  overflowY={'scroll'}
                  sx={{
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                  position={'relative'}
                  px="auto"
                >
                  {users && users?.length > 0 ? (
                    users?.map((item, index) => (
                      <Box
                        position={'relative'}
                        display={'flex'}
                        flexDir={'column'}
                        alignItems={'center'}
                        gap={4}
                        cursor={'pointer'}
                        maxW={50}
                        onClick={() => gotoFollower(item?.address)}
                      >
                        <Image
                          w={8}
                          h={8}
                          src={item?.photo_url || '/images/default_avatar.png'}
                          rounded="full"
                          key={item?.address + index}
                        />
                        <Text fontWeight={'bold'} fontSize={12} textAlign={'center'}>
                          {' '}
                          {item?.display_name
                            ? item?.display_name.startsWith('0x')
                              ? getEllipsisTxt(item?.display_name, 3)
                              : getEllipsisTxt(item?.display_name, 3)
                            : cutEllipsisTxtWithLength(item?.address, 18)}
                        </Text>
                      </Box>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </Collapse>
              <Image
                src={`/images/icon/down.png`}
                transform={show ? 'rotate(180deg)' : 'none'}
                onClick={handleToggle}
                mt={2}
                mb={10}
                cursor={'pointer'}
                display={userListHeight > 110 ? 'block' : 'none'}
              />
              <Button
                bg="linear-gradient(180deg, #82FCD3 0%, #5CC99C 100%)"
                rounded="full"
                color="#000"
                fontSize="14"
                fontWeight="700"
                w={260}
                h={22}
                p={4}
                onClick={openFollowerModal}
                bottom={8}
                alignSelf={'flex-end'}
                mx="auto"
              >
                See All Followers
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
      <ShareModal showShareModal={shareOpen} onShareModalOpen={openShareModal} onShareModalClose={closeShareModal} />
      <ProfileModal
        showShareModal={profileOpen}
        onShareModalOpen={openProfileModal}
        onShareModalClose={closeProfilModal}
      />
      <FollowerModal
        showShareModal={followerOpen}
        onShareModalOpen={openFollowerModal}
        onShareModalClose={closeFollowerModal}
      />
      {isMsgOpen && (
        <ConsumerSellSpot
          showMessage={isMsgOpen}
          onShowMessageClose={onMsgClose}
          onShowMessageOpen={onMsgOpen}
          targetUser={targetUser}
          refetchList={listOccupiedSpots}
        />
      )}
    </Box>
  );
}

export default PointsConsumer;
