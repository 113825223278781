import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import { setPoints } from './claimPoint';

export const useClaimPoint = () => {
  const dispatch = useDispatch<AppDispatch>();
  const points = useSelector((state: RootState) => state.claimPoints.addedPoints);
  const changePoints = useCallback(
    (points: string) => {
      dispatch(setPoints(points));
    },
    [dispatch, points, setPoints],
  );

  return { changePoints, points };
};
