import { AxiosInstance } from 'axios';
export const updateConsumerProfile = async (display_name: string, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/update_profile/`, { display_name });

  return response?.data;
};

export const updateAvatar = async (formData: any, instance?: AxiosInstance) => {
  if (!instance) {
    console.log('instance is null');
    return undefined;
  }
  const response = await instance.post(`/wallets/update_avatar/`, { formData });

  return response?.data;
};
