import { FC, ReactNode, useEffect } from 'react';
import { faker } from '@faker-js/faker';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Footer, Header } from 'components/modules';
import Head from 'next/head';
import SideInfoRight from '@/components/SideInfo/SideInfoRight';
import SideInfoLeft from '@/components/SideInfo/SideInfoLeft';
import { basicConfig } from '@/configs';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import store from '../../../../redux/store';

interface DefaultProps {
  myAddress?: string;
  children: ReactNode;
  pageName: string;
  showInfoOnLeft?: boolean;
  showInfoOnRight?: boolean;
  loadingWhoHuntedYou?: boolean;
  huntingList?: { saved: any[]; created: any[] };
  loadingCheckoutAddress?: boolean;
  checkoutAddressList?: any[];
  loadedAddress?: string;
  whoHuntedYouList?: any[];
  couldConnect?: boolean;
  useSpots?: boolean;
  setUseSpots?: (isUseSpots: boolean) => void;
}
const localStorageMode = typeof window !== 'undefined' ? localStorage.getItem('chakra-ui-color-mode') : undefined;

const Default: FC<DefaultProps> = (props) => {
  const {
    myAddress = '',
    children,
    pageName,
    showInfoOnLeft = false,
    showInfoOnRight = false,
    loadedAddress = '',
    loadingWhoHuntedYou = false,
    whoHuntedYouList = [],
    loadingCheckoutAddress = false,
    checkoutAddressList = [],
    huntingList = {
      saved: [],
      created: [],
    },
    couldConnect = true,
    useSpots = false,
    setUseSpots,
  } = props;

  const randomSDKString = faker.string.alpha(10);
  useEffect(() => {
    if (localStorageMode && localStorageMode !== 'dark') {
      localStorage.setItem('chakra-ui-color-mode', 'dark');
    }
  }, [localStorageMode]);
  return (
    <Provider store={store}>
      <Head>
        <title>{`${pageName} | Bluwhale.com`}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <script
          src="https://web3-wallet-dashboard-api-q67p7dk34q-uc.a.run.app/static/index.js"
          bluwhale-sdk="start"
          id="55fc7deb-f3bf-4196-a326-8b601c3a73c4"
          async
        ></script>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Head>
      <Flex flexDir={'column'}>
        <Header couldConnect={couldConnect} useSpots={useSpots} />
        {/* <Container maxW="none" p={3} as="main" minH="70vh" px={6}> */}
        {/* <Grid templateColumns={'1fr minmax(1024px, 1fr) 1fr'} gap={6}> */}
        <Flex justifyItems={'center'} justifyContent={'space-around'}>
          {showInfoOnLeft && (
            <Box
              w="300px"
              px={4}
              justifyContent="flex-end"
              display={{
                base: 'none',
                md: 'flex',
              }}
            >
              <SideInfoLeft myAddress={myAddress} huntingList={huntingList} />
            </Box>
          )}
          {children}

          {showInfoOnRight && (
            <Box
              zIndex="50"
              px={4}
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              <SideInfoRight
                myAddress={myAddress}
                loadingWhoHuntedYou={loadingWhoHuntedYou}
                loadingCheckoutAddress={loadingCheckoutAddress}
                checkoutAddressList={checkoutAddressList}
                whoHuntedYouList={whoHuntedYouList}
                huntingList={huntingList}
                loadedAddress={loadedAddress}
                useSpots={useSpots}
                setUseSpots={setUseSpots}
                couldConnect={couldConnect}
              />
            </Box>
          )}
        </Flex>
        {/* </Container> */}
        <Footer />
      </Flex>
    </Provider>
  );
};

export default Default;
