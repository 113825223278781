/* eslint-disable no-useless-escape */
/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str?: string, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return '';
};

export const cutEllipsisTxtWithLength = (str?: string, n = 6) => {
  if (str && str.length > n) {
    return `${str.slice(0, n)}...`;
  }

  return str || '';
};
export function separator(numb: string | number, decimal = 2): string {
  if (!numb && numb !== 0) {
    return '';
  }
  const str = numb.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (str.length === 2) {
    str[1] = str[1]?.substring(0, decimal);
  }
  return str.join('.');
}

export function formatNumber(value: number, fixed?: number): string {
  const units = ['', 'K', 'M', 'B', 'T'];
  let unitIndex = 0;

  while (value >= 1000 && unitIndex < units.length - 1) {
    value /= 1000;
    unitIndex++;
  }

  return value.toFixed(fixed ?? 1) + units[unitIndex];
}

export function isURL(str: string) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(str);
}

export function isValidEmail(email: string): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
