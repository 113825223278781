import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useAccount, useBalance, useDisconnect, useEnsName } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';

import {
  Box,
  Heading,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Popover,
  HStack,
  Button,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Image,
  Text,
  Divider,
  Card,
  Collapse,
  Tooltip,
  Input,
  useClipboard,
  useBoolean,
} from '@chakra-ui/react';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import { isMobile } from 'react-device-detect';

import { event } from 'nextjs-google-analytics';
import { cutEllipsisTxtWithLength, getEllipsisTxt, separator } from 'utils/format';
import { useRouter } from 'next/router';
import Loading from '@/components/modules/Loading';
import SideInfoRightWhoHuntedYou from './SideInfoRightWhoHuntedYou';
import SideInfoRightCheckoutAddresses from './SideInfoRightCheckoutAddresses';
import Paywall from '../modules/Paywall';
import SideInfoHuntingList from './SideInfoHuntingList';
import { events } from '@/constants/analytics';
import PointsConsumer from './PointsConsumer';
import { useBluwhale } from '@/context/BluwhaleContext';
import { useFetchUserClaimStatus } from '../../../redux/useFetchUserClaimStatus';
import { RootState } from '../../../redux/store';
import { useEnterprisePoints } from '../../../redux/useEnterprisePoints';
import { updateConsumerProfile } from '../../../pages/api/consumer_profile';
import { useConsumerInfo } from '../../../redux/useConsumerInfo';
import { useConsumerPoints } from '../../../redux/useConsumerPoints';
import { useGetEnterpriseProjectInfo } from '../../../redux/useGetEnterpriseProjectInfo';
import { fetchEnterpriseClaimPoints } from '../../../pages/api/enterpriseapi';
import { basicConfig } from '@/configs';
import axios from 'axios';
import ProfileModal from '../modules/ProfileModal';

interface SideInfoRightProps {
  myAddress?: string;
  loadingWhoHuntedYou: boolean;
  loadingCheckoutAddress: boolean;
  checkoutAddressList: any[];
  whoHuntedYouList: any[];
  huntingList: { saved: any[]; created: any[] };
  loadedAddress?: string;
  useSpots?: boolean;
  setUseSpots?: (isUseSpots: boolean) => void;
  couldConnect?: boolean;
}

const SideInfoRight: FC<SideInfoRightProps> = (props) => {
  const {
    myAddress = '',
    loadingWhoHuntedYou,
    loadingCheckoutAddress,
    checkoutAddressList,
    whoHuntedYouList,
    huntingList,
    loadedAddress = '',
    useSpots = false,
    setUseSpots,
    couldConnect = true,
  } = props;
  const { isOpen: showShareModal, onOpen: onShareModalOpen, onClose: onShareModalClose } = useDisclosure();
  const router = useRouter();
  const { user, onLogout, axiosInstance, firebaseUser } = useBluwhale();
  const toast = useToast();
  const handleWhoHuntedYouClick = () => {
    event(events.connectwallet_display, {
      source: 'btn_whoHuntYou',
    });
    router.push('/consumer/login?type=web3&from=btn_whoHuntYou');
  };

  const handleNotifyMeViaEmailClick = () => {
    event(events.btn_notify_me_email);
  };

  const handleSeeMoreClick = () => {
    toast({
      description: 'Exciting features coming soon!',
      // description: (e as { message: string })?.message,
      status: 'success',
      position: 'top',
    });
  };
  const { onCopy } = useClipboard(user?.address || '');
  const { address } = useAccount();
  const { user_type } = user ?? {};
  const enterpriseDetails = useSelector((state: RootState) => state.enterpriseDetail.enterpriseDetail);

  const { data: enterprisePoints, refetch, status } = useEnterprisePoints();
  const consumerInfo = useSelector((state: RootState) => state.consumerInfo);

  const { refetch: refetchUserInfo } = useConsumerInfo();

  const [image, setImage] = useState<File | null>(null);
  const uploadImageInputRef = useRef<HTMLInputElement>(null);

  const [editingName, setEditingName] = useState(consumerInfo?.display_name);
  const [flag, setFlag] = useBoolean();

  const {
    data: consumerpointsInfo,
    refetch: refetchConsumerPoints,
    status: consumerPointsStatus,
  } = useConsumerPoints();
  const { consumerPoints } = consumerpointsInfo ?? {};

  const { data: projectInfo } = useGetEnterpriseProjectInfo();

  const { project_favicon_url, owned_project, wallet_amount } = enterpriseDetails || {};

  const { result, refetch: refetchUserClaim } = useFetchUserClaimStatus();

  const [displayEnteprisePoints, setDisplayEnterprisePoints] = useState(0);
  const { claimed: hasReward, reward_blu: rewardBlu, reward_usd: rewardUsd } = result ?? {};
  const {
    data: ensName,
    isError,
    isLoading,
  } = useEnsName({
    address,
  });
  const { data: balance } = useBalance({
    address,
  });

  const { disconnect } = useDisconnect({
    onSuccess(data) {
      console.log('Success', data);
    },
  });
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const handleMyWalletClick = async () => {
    await router.push(`/wallet/${user?.address || user?.display_name}`);
    router.reload();
  };
  const handleConsumerSigninClick = () => {
    event(events.connectwallet_display, {
      source: 'btn_connectwallet',
    });
    const currentPath = router.asPath;
    let path = `/consumer/login?type=web3&from=${currentPath}`;

    router.push(path);
  };

  const handleShowMySite = async () => {
    router.push(`/enterprise`);
  };

  const getEnterprisePoints = async () => {
    const { increased_point }: { increased_point: number } = await fetchEnterpriseClaimPoints(axiosInstance);
    setDisplayEnterprisePoints(increased_point);
  };
  const uploadAvatar = async (selectedImage: File | null) => {
    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    const bearerToken = token ? `Bearer ${token}` : undefined;
    if (!selectedImage) {
      console.error('No image selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedImage);

      // Replace 'upload_url' with the actual URL to your server endpoint
      const response = await axios.post(`${basicConfig.bluwhale.walletAPIUrl}/wallets/update_avatar/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${bearerToken}`,
        },
      });
      await refetchUserInfo();
      toast({
        description: `Image uploaded successfully`,
        status: 'success',
        position: 'top',
      });
      console.log('Image uploaded successfully:', response.data);
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      setImage(null);
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);

      // Trigger uploadAvatar function after setting the image
      uploadAvatar(selectedImage);
    }
  };

  const handleEditingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditingName(event?.target?.value);
  };

  const updateConsumerName = async () => {
    setFlag.off();
    await updateConsumerProfile(editingName, axiosInstance);
    await refetchUserInfo();
    toast({
      description: `Name updating successfully`,
      status: 'success',
      position: 'top',
    });
  };
  const copyCode = async () => {
    await onCopy();
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };
  useEffect(() => {
    if (flag) {
      setEditingName(consumerInfo?.display_name);
    }
  }, [flag]);

  useEffect(() => {
    if (address) {
      refetchUserClaim();
    }
  }, [address]);

  const closeProfit = () => {
    if (displayEnteprisePoints) {
      getEnterprisePoints();
    }
  };

  useEffect(() => {
    let timer: any = null;
    if (displayEnteprisePoints) {
      let timer = setTimeout(() => {
        getEnterprisePoints();
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [displayEnteprisePoints]);

  useEffect(() => {
    if (user?.user_type === 'enterprise') {
      getEnterprisePoints();
    }
  }, [user?.user_type]);

  const getUserName = () => {
    if (consumerInfo?.display_name) {
      return consumerInfo?.display_name?.includes?.('0x')
        ? getEllipsisTxt(user?.display_name)
        : consumerInfo?.display_name;
    }
    if (ensName) {
      return `@${ensName}`;
    }
    if (!user?.display_name) {
      return getEllipsisTxt(address);
    }
    if (user?.display_name?.includes('0x')) {
      return getEllipsisTxt(user?.display_name);
    }
    return user?.display_name;
  };
  const userName = getUserName();
  return (
    <Box maxW="313px" minH={'100%'} pt={['0', null, '98px']} display="flex" flexDirection="column" zIndex={1000}>
      {/* Points system right banner*/}
      {couldConnect && !isMobile && (
        <Box gap={'10px'} display={'flex'} flexDir={'column'} position={'absolute'} top={4} bg="#000">
          {user ? (
            <Flex flexDir={'column'} gap={4}>
              <Button
                colorScheme="purple"
                bg="#6235D0"
                rounded="full"
                color="#fff"
                fontSize="xs"
                fontWeight="bold"
                p="0"
                px={['4', null, '6']}
                onClick={onShareModalOpen}
              >
                <>
                  <Box
                    position="relative"
                    boxSize="24px"
                    rounded="20px"
                    bgImage={consumerInfo?.photo_url || project_favicon_url || '/images/default_avatar.png'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgPosition="center center"
                    bgSize="cover"
                    bgBlendMode=""
                    mr="2"
                  />
                  {/* {ensName ? `@${ensName}` : userInfo?.name ?? getEllipsisTxt(address)} */}
                  {cutEllipsisTxtWithLength(userName)}
                </>
              </Button>
            </Flex>
          ) : (
            <Button
              colorScheme="purple"
              bg="#6235D0"
              rounded="full"
              color="#fff"
              fontSize="xs"
              fontWeight="bold"
              p="0"
              px="8"
              onClick={handleConsumerSigninClick}
            >
              Connect Wallet
            </Button>
          )}
        </Box>
      )}

      {user && <PointsConsumer useSpots={useSpots} setUseSpots={setUseSpots} />}
      {/* Who viewed your profile */}
      {myAddress && (
        <SideInfoRightWhoHuntedYou
          loading={loadingWhoHuntedYou}
          list={whoHuntedYouList}
          onSeeMoreClick={handleSeeMoreClick}
        />
      )}
      {!myAddress && (
        <Button
          bgColor="#E43030"
          w="100%"
          h="48px"
          mb={16}
          rounded="24px"
          color="#fff"
          fontSize="16px"
          fontWeight="bold"
          onClick={handleWhoHuntedYouClick}
        >
          <Image src="/images/ic_info.png" mr={4} />
          Who viewed your profile?
        </Button>
      )}
      {/* NOTE: Following List */}
      <SideInfoHuntingList
        myAddress={myAddress}
        huntingList={huntingList}
        display={{
          base: 'block',
          md: 'none',
        }}
      />

      {/* Check out these addresses */}
      <SideInfoRightCheckoutAddresses loading={loadingCheckoutAddress} list={checkoutAddressList} />
      {/* NOTE: Paywall */}
      {/* <Modal isOpen={isPaywallOpen} onClose={onPaywallClose} size="full">
        <ModalOverlay />
        <ModalContent backgroundColor="#000" border="1px solid #82FCD3" rounded="2xl">
          <ModalHeader fontWeight="bold"></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Heading mx="auto" mb="4">
                Plans & Prices
              </Heading>
              <Text mx="auto" style={{ letterSpacing: '0.05em' }}>
                Choose the best fitting plan and grow your brand & business today!
              </Text>
              <Flex>
                <Paywall />
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <ProfileModal
        showShareModal={showShareModal}
        onShareModalClose={onShareModalClose}
        onShareModalOpen={onShareModalOpen}
      />
    </Box>
  );
};

export default SideInfoRight;
