import { checkEnterPriseDetail } from '@/../pages/api/enterpriseapi';
import { useBluwhale } from '@/context/BluwhaleContext';
import router from 'next/router';
import { useQuery } from 'react-query';
import { auth } from '@/configs/firebase';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { setEnterpriseDetail } from './enterpriseDetails';
import { useRouter } from 'next/router';

export function useGetEnterpriseDetail() {
  const router = useRouter();

  const { onLogout, user: bluUser, axiosInstance } = useBluwhale();
  const [status, setStatus] = useState('idle');
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  // Function to fetch enterprise detail data
  const dispatch = useDispatch<AppDispatch>();
  const enterpriseDetail = useSelector((state: RootState) => state.enterpriseDetail.enterpriseDetail);
  const changeEnterpriseDetails = useCallback(
    (details: any) => {
      dispatch(setEnterpriseDetail(details));
    },
    [dispatch, setEnterpriseDetail],
  );
  async function getData() {
    try {
      if (!getEnable() || status !== 'idle') {
        return undefined;
      }
      setStatus('loading');

      const result = await checkEnterPriseDetail(axiosInstance);
      setStatus('success');
      await changeEnterpriseDetails(result);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // handle 401 error here
        // TODO : refresh token
        onLogout();
        router.push('/login');
      }
      setStatus('error');
      throw error;
    }
  }
  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && bluUser?.user_type === 'enterprise') || false;
  }

  const refetch = () => {
    if (user && bluUser?.user_type === 'enterprise') {
      getData();
    }
  };
  useEffect(() => {
    if (!enterpriseDetail && user && bluUser?.user_type === 'enterprise' && status === 'idle') {
      getData();
    }
  }, [enterpriseDetail, user, bluUser?.user_type, bluUser, router?.isReady]);
  return { data: enterpriseDetail, refetch, status };
}
