import React from 'react';
import Image from 'next/image';

function NoData() {
  return (
    <div className="flex flex-col justify-center items-center h-full text-[#4C6984]">
      <Image src="/images/nodata.svg" height={50} width={50} alt="NoData" />
      <div className="text-sm mt-1">No Data</div>
    </div>
  );
}
export default NoData;
