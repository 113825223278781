import { useBluwhale } from '@/context/BluwhaleContext';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { auth } from '@/configs/firebase';
import { setenterprisePoints } from './enterprisePoints';
import { getEnterprisePointsInfo } from '../pages/api/consumer_points';

export const useEnterprisePoints = () => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;

  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState('idle');
  const { user: BluUser, axiosInstance } = useBluwhale();
  const enterprisePoints = useSelector((state: RootState) => state.enterprisePoints.enterprisePoints);

  async function fetchData() {
    try {
      if (!getEnable()) {
        return undefined;
      }
      setStatus('loading');
      const pointsInfo = await getEnterprisePointsInfo(axiosInstance);
      setStatus('succeeded');
      if (pointsInfo) {
        dispatch(setenterprisePoints(pointsInfo));
      }
    } catch (error) {
      setStatus('failed');
      console.error('Error fetching campaign list:', error);
    }
  }

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && BluUser?.user_type === 'enterprise') || false;
  }
  useEffect(() => {
    if (!enterprisePoints && status === 'idle' && BluUser?.user_type === 'enterprise') {
      fetchData();
    }
  }, [enterprisePoints, status, BluUser?.user_type]);

  const refetch = async () => {
    fetchData();
  };

  return { data: enterprisePoints, status, refetch };
};
