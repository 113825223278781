import { useBluwhale } from '@/context/BluwhaleContext';
import router from 'next/router';
import { useQuery } from 'react-query';
import { auth } from '@/configs/firebase';
import { getUserCampaigns } from '../../pages/api/userClaim';

export function useGetConsumerCompaigns() {
  const { onLogout, user: bluUser, onSetUser, axiosInstance } = useBluwhale();
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  async function getData() {
    try {
      if (!getEnable()) {
        return undefined;
      }
      if (bluUser?.address) {
        const { inbox } = await getUserCampaigns(bluUser?.address, axiosInstance);

        return inbox?.reverse();
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        // handle 401 error here
        //TODO : refresh token
        onLogout();
        router.push('/login');
      }
      return Promise.reject(error);
    }
  }
  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && bluUser?.user_type === 'enterprise') || false;
  }
  const query = useQuery({
    queryKey: ['ConsumerCompaigns'],
    queryFn: async () => getData(),
  });
  const { data, status, refetch } = query;
  return { data, status, refetch };
}
