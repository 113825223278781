import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Card,
  Image,
  Avatar,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
} from '@chakra-ui/react';
import { cutEllipsisTxtWithLength, isURL, getEllipsisTxt } from '@/utils/format';
import { useAccount } from 'wagmi';
import { useBluwhale } from '@/context/BluwhaleContext';
import { basicConfig } from '@/configs';

interface PostMessageModalProps {
  showMessage: boolean;
  onShowMessageOpen: () => void;
  onShowMessageClose: () => void;
  depositeBLU: () => void;
  price: number;
  isOnChain: boolean;
  feeRate: number;
}
const BuyBLU = ({
  showMessage,
  onShowMessageOpen,
  onShowMessageClose,
  depositeBLU,
  price,
  feeRate,
  isOnChain,
}: PostMessageModalProps) => {
  const { user, onLogout, axiosInstance } = useBluwhale();

  return (
    <Modal isOpen={showMessage} onClose={onShowMessageClose}>
      <ModalOverlay
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="full"
        h="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
        zIndex="1000"
      />
      <ModalContent
        backgroundColor={'#000'}
        border={'1px solid #82FCD3'}
        rounded="2xl"
        minH={500}
        display={'flex'}
        justifyContent={'center'}
        minW={[357, 600, 840]}
        ml={['5%', null, null]}
        p={[4, null, 10]}
      >
        <ModalHeader fontWeight="bold" display={'flex'} flexDir={'column'}>
          {' '}
          <Heading mx="auto" mb="4" textAlign={'center'}>
            Get BLUAI Points
          </Heading>
          <Text alignSelf={'center'} textAlign={'center'} display={'flex'} alignItems={'center'}>
            To continue messaging, please purchase BLUAI points.{' '}
          </Text>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody display={'flex'} flexDir={'column'}>
          <Box alignSelf={'center'} py={1} bg={'rgba(98, 150, 212, 0.2)'} borderRadius={10} my={8} w="full" p={8}>
            <Card
              variant="outline"
              bg={'transparent'}
              display={'flex'}
              flexDir={['column','row']}
              justifyContent={'space-between'}
              w={'full'}
              p={4}
            >
              <Box >
                <Text opacity={0.7} mb={4}>
                  {' '}
                  Purchase from
                </Text>
                <Box display={'flex'} alignItems={'center'} fontWeight={700} fontSize={24}>
                  <Image alt="eth" src="/eth_logo.png" rounded={'full'} width={8} height={8} mr={4} />
                  <Flex flexDir={'column'} alignItems={'center'} alignSelf={'flex-start'}>
                    <Text fontSize={24} textAlign={'left'} alignSelf={'flex-start'}>
                      Bluwhale.eth
                    </Text>
                    <Text fontSize={12} textAlign={'left'} alignSelf={'flex-start'} color={'#6C6C6C'}>
                      {' '}
                      {getEllipsisTxt(basicConfig.bluwhaleWallet, 4)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
              <Box>
                <Text opacity={0.7} mb={2}>
                  {' '}
                  Cost
                </Text>
                <Text display={'flex'} alignItems={'center'} fontWeight={700} fontSize={24} height={10}>
                  {price * (1 + feeRate) * 0.0001} ETH
                </Text>
                <Text color={'#00FFFF'}>1 BLU = 0.0001 ETH</Text>
              </Box>
            </Card>
          </Box>

          <Button
            display="flex"
            alignSelf={'center'}
            alignItems="center"
            fontSize="md"
            fontWeight="bold"
            py={3}
            px={10}
            my={10}
            rounded={'full'}
            bg={'#6235D0'}
            color="#fff"
            w={326}
            flex={1}
            onClick={depositeBLU}
          >
            Continue
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
console.log(123);
export default BuyBLU;
