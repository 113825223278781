export const TOKEN_COLORS: { [key: string]: string } = {
  BNB: '#FFD700',
  MATIC: '#00FF00',
  COMP: '#32CD32',
  LINK: '#00CED1',
  USDT: '#008080',
  USDC: ' #9400D3',
  ETH: '#0096FF',
  Ethereum: '#0096FF',
};

export const filterTokenAddrs = ['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'];
