export const events = {
  btn_learn_more: 'btn_learn_more',
  btn_notify_me_email: 'btn_notify_me_email',
  btn_whoHuntYou: 'btn_whoHuntYou',
  btn_follow: 'btn_follow',
  btn_un_follow: 'btn_un_follow',
  btn_my_group_result: 'btn_my_group_result',
  btn_profile_now: 'btn_profile_now',
  whoHuntYou_list: 'whoHuntYou_list',
  checkout_address: 'checkout_address',
  btn_contact_us: 'btn_contact_us',
  connect_wallet_display: 'connect_wallet_display',
  connect_wallet_success: 'connect_wallet_success',
  btn_import_users: 'btn_import_users',
  rate_limit_exceed: 'rate_limit_exceed',
  connectwallet_success: 'connectwallet_success',
  first_entry: 'first_entry',
  msg_viewed: 'msg_viewed',
  msg_timespent: 'msg_timespent',
  msg_open: 'msg_open',
  msg_cta: 'msg_cta',
  btn_openMoreSpots: 'btn_openMoreSpots',
  btn_copyShareLink: 'btn_copyShareLink',
  share_by: 'share_by',
  similar_wallets: 'similar_wallets',
  btn_listview: 'btn_listview',
  btn_msg_click: 'btn_msg_click',
  btn_msg_sent: 'btn_msg_sent',
  msg_success: 'msg_success',
  msg_failed: 'msg_failed',
  btn_claim_profile: 'btn_claim_profile',
  linksocial_choose: 'linksocial_choose',
  not_have_wallet: 'not_have_wallet',
  connectwallet_choose: 'connectwallet_choose',
  connectwallet_display: 'connectwallet_display',
  signUp_step2_display: 'signUp_step2_display',
  signUp_step2_create: 'signUp_step2_create',
  signUp_step2_cancel: 'signUp_step2_cancel',
  signUp_click: 'signUp_click',
  signInSsocial_choose: 'signInSsocial_choose',
  signUpSsocial_choose: 'signUpSsocial_choose',
  click_recommendedProjects: 'click_recommendedProjects',
  btn_addUsers_to_outreach: 'btn_addUsers_to_outreach',
  btn_userlist_sendMsg: 'btn_userlist_sendMsg',
  btn_compare: 'btn_compare',
  btn_importWallet_Submit: 'btn_importWallet_Submit',

  //spots market
  btn_buy_trending_spot: 'btn_buy_trending_spot',
  buy_trending_spot_success: 'buy_trending_spot_success',
  btn_trade_and_earn: 'btn_trade_and_earn',
  claimed_spot: 'claimed_spot',
  btn_sell_spot: 'btn_sell_spot',
  btn_sell_spot_confirm: 'btn_sell_spot_confirm',
  btn_buy_market_spot: 'btn_buy_market_spot',
  buy_market_spot_confirm: 'buy_market_spot_confirm',
};
