import React from 'react';
import { Box } from '@chakra-ui/react';
import NoData from '@/components/modules/DashBoard/elements/NoData';

interface Props {
  children: React.ReactNode;
  isEmpty: boolean;
}

const EmptyState = (props: Props) => {
  const { children, isEmpty } = props;

  if (isEmpty) {
    return (
      <Box pt="8">
        <NoData />
      </Box>
    );
  }

  return <Box>{children}</Box>;
};

export default EmptyState;
