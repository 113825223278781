import { ReactNode } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

type LoadingProps = {
  loading: boolean;
  minH?: string;
  children: ReactNode;
};

const Loading = (props: LoadingProps) => {
  const { children, loading, minH = '180px' } = props;

  return (
    <Box className="transition-all ease-linear overflow-hidden">
      {loading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minH={minH}>
          <Image w="9" h="9" src="/images/ic_brain.gif" />
          <Text
            className="inline-block"
            mx="auto"
            mt="2"
            fontSize="12px"
            key={'loading'}
            variant="outline"
            color="#B1B1B1"
            alignSelf="flex-start"
          >
            Analyzing...
          </Text>
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default Loading;
